import React, { Component, FormEvent } from 'react';
import { Redirect } from 'react-router-dom';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonNote,
  IonButton,
  IonList,
  IonAlert,
  IonCol,
  IonRow,
  IonDatetime,
  IonCard,
  IonCardContent,
} from '@ionic/react';
import axios from 'axios';
import { HomeNavButtons } from '../menu/HomeNavButtons';
import './Recover.css';

export class Recover extends Component {
  private user = { emailAddress: '', dob: new Date('') };

  private emailValidation = '';

  state = {
    redirect: '',
    showMessage: false,
    isEnabled: false,
    isEmailValid: false,
    isPasswordValid: false,
    message: '',
  };

  validateFields(): any {
    if (
      this.user.emailAddress === '' ||
      this.user.dob === new Date('') ||
      !this.isEmailValid
    ) {
      this.setState({ isEnabled: false });
    } else {
      this.setState({ isEnabled: true });
    }
  }

  isEmailValid(): boolean {
    //Regular expression for simple email format check
    const emailRegExp = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (emailRegExp.test(this.user.emailAddress)) {
      this.emailValidation = '';
      this.setState({ isEmailValid: true });
      this.validateFields();
      return true;
    } else {
      this.emailValidation = 'Please enter Valid Email Address';
      this.setState({ isEmailValid: false });
      this.setState({ isEnabled: false });
      return false;
    }
  }

  // JSON "get" example

  async handleSubmit(e: FormEvent) {
    e.preventDefault();

    this.user.dob = new Date(
      `${this.user.dob.getFullYear()} ${this.user.dob.getMonth() + 1} ${
        this.user.dob.getDate() + 1
      }`
    );

    axios
      .post(process.env.REACT_APP_API_FORGOT_PASSWORD || '', this.user)

      .then((response) => {})
      .catch((error) => {});
    this.setState({ showMessage: true });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <IonPage id="recover-page">
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <HomeNavButtons />
            </IonButtons>
            <IonTitle class="recover-title">Forgot Password</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonCardContent class="recover-card">
              <IonNote>
                Enter your email address and Date of Birth to recover password
              </IonNote>
              <IonAlert
                isOpen={this.state.showMessage}
                header="Verfication Code"
                message="Verification code will be been sent to email address if entered email address and DOB exist"
                buttons={[
                  {
                    text: 'OK',
                    handler: () => {
                      this.setState({ redirect: '/recoverpassword' });
                    },
                  },
                ]}
              />
              <div className="body">
                <form
                  onSubmit={(e) => this.handleSubmit(e)}
                  action="post"
                  className="ion-padding"
                >
                  <IonList>
                    <IonItem>
                      <IonLabel position="floating" color="primary">
                        Email Address
                      </IonLabel>
                      <IonInput
                        name="email"
                        type="email"
                        value={this.user.emailAddress}
                        spellCheck={false}
                        autocapitalize="off"
                        onIonChange={(e) =>
                          (this.user.emailAddress = e.detail.value!)
                        }
                        onIonBlur={() => this.isEmailValid()}
                        required
                      ></IonInput>
                    </IonItem>
                    <IonNote color="danger">{this.emailValidation}</IonNote>
                    <br />
                    <IonItem>
                      <IonLabel position="floating" color="primary">
                        Date of Birth
                      </IonLabel>
                      <IonDatetime
                        name="dob"
                        onIonChange={(e) =>
                          (this.user.dob = new Date(e.detail.value!))
                        }
                        onIonBlur={() => this.validateFields()}
                      ></IonDatetime>
                    </IonItem>
                  </IonList>

                  <IonRow>
                    <IonCol>
                      <IonButton
                        disabled={!this.state.isEnabled}
                        type="submit"
                        expand="block"
                      >
                        Submit
                      </IonButton>

                      <a href="/recoverpassword" className="link">
                        Already got a verification code?
                      </a>

                      {/*{!this.state.isEnabled ? <p className='red-text'>{`You have logged in ${this.user.emailAddress}`}</p> : null}*/}
                    </IonCol>
                  </IonRow>
                </form>
              </div>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}

export default Recover;
