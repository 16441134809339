import React, { Component } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonButtons,
  IonList,
} from '@ionic/react';
import { Cookies } from 'react-cookie';
import { AccountNavButtons } from '../menu/AccountNavButtons'
import './tips.css'
import bathroom from '../img/bathroom.jpg';
import kitchen from '../img/kitchen.jpg'
import garden from '../img/garden.jpg'

const cookies: Cookies = new Cookies();

export class Tips extends Component {
  

  render() {
    return (
      <>
        <IonPage id="tips-page">
          <IonHeader>
            <IonToolbar color="dark">
              <IonButtons slot="start">
                <AccountNavButtons />
              </IonButtons>
              <IonTitle class="title">Tips</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className='flex-container'>
              <IonCard className='bathroom-card'>
              <img className='profile' src={bathroom} alt='bth' />
                  <IonCardContent className='bathroom-content'>
                  <IonCardTitle className='bthTitle'>Bathroom</IonCardTitle>
                      <IonList className='bthlist'>
                        <li className='addSpace'>(1) Take shorter showers (10 mins max)</li>
                        <li className='addSpace'>(2) Install water-saving shower heads or flow restrictors</li>
                        <li className='addSpace'>(3) Turn off the water while brushing your teeth</li>  
                      </IonList>
                  </IonCardContent>
              </IonCard>
              <IonCard className='kitchen-card'>
              <img className='profile' src={kitchen} alt='kth' />
                  <IonCardContent className='kitchen-content'>
                  <IonCardTitle className='kthTitle'>Kitchen</IonCardTitle>
                      <IonList className='kthlist'>
                        <li className='addSpace'>(1) Don't use water for defrosting</li>
                        <li className='addSpace'>(2) Hold off on running the dishwasher until you have a full load of dishes</li>
                        <li className='addSpace'>(3) If you wash dishes by hand, don't leave the water running for rinsing</li>  
                      </IonList>
                  </IonCardContent>
              </IonCard>
              <IonCard className='garden-card'>
              <img className='profile' src={garden} alt='gdn' />
                  <IonCardContent className='garden-content'>
                  <IonCardTitle className='gdnTitle'>Garden</IonCardTitle>
                      <IonList className='gdnlist'>
                        <li className='addSpace'>(1) Water during the cool parts of the day</li>
                        <li className='addSpace'>(2) Plant drought-resistant trees and plants</li>
                        <li className='addSpace'>(3) Check taps and pipes for leaks</li>
                        <li className='last'><a href='https://ccc.govt.nz/services/water-and-drainage/water-supply/about-the-water-supply/water-saving-tips'>see more</a></li>  
                      </IonList>
                  </IonCardContent>
              </IonCard>
          </div>
          </IonContent>
        </IonPage>
      </>
    );
  }
}

export default Tips