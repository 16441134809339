import React, { Component, FormEvent } from 'react';
import { Redirect } from 'react-router-dom';
import {
  IonHeader,
  IonNote,
  IonAlert,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  withIonLifeCycle,
  IonCard,
  IonCardContent,
  IonButtons,
  IonIcon,
  IonSpinner
} from '@ionic/react';
import './login.css';
import axios from 'axios';
import sha256 from 'sha256';
import { HttpStatus } from '@flow-watch/model/httpStatus';
import { Plugins } from '@capacitor/core';
import './login.css';
import { HomeNavButtons } from '../menu/HomeNavButtons';
import {
 eyeOutline,
 eyeOffOutline,
} from 'ionicons/icons';


import { Cookies } from 'react-cookie';

export const { Storage } = Plugins;

const cookies: Cookies = new Cookies();
export class Login extends Component {
  private loginResponse = { header: '', message: '' };
  private user = { emailAddress: '', password: '' };

  private emailValidation = '';
  private passwordValidation = '';

  componentDidMount() {
    const authParameter = cookies.get('authParameter');
    if (authParameter !== undefined) {
      this.findEmailAndToken(authParameter.emailAddress, authParameter.token)
        .then((res) => {
          this.setState({ redirect: '/user/dashboard' });
        })
        .catch(() => {});
    }
  }
  state = {
    redirect: '',
    showMessage: false,
    isEnabled: false,
    isEmailValid: false,
    isPasswordValid: false,
    isChanged: false,
    message: '',
    showPassword: false,
    isNotPressed: true,
    isFormValid: false
  };

  toggleShow() {
    this.setState({ showPassword : !this.state.showPassword })
  }

  validateFields(): any {
    if (
      this.user.emailAddress === '' ||
      this.user.password === '' ||
      !this.state.isEmailValid
    ) {
      this.setState({ isEnabled: false });
    } else {
      this.setState({ isEnabled: true });
    }
  }

  isEmailValid(): boolean {
    //Regular expression for simple email format check
    const emailRegExp = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (emailRegExp.test(this.user.emailAddress)) {
      this.emailValidation = '';
      this.setState({ isEmailValid: true });
      this.validateFields();
      return true;
    } else {
      // this.emailValidation = 'Please enter Valid Email Address';
      this.setState({ isEmailValid: false });
      this.setState({ isEnabled: false });
      // this.validateFields();
      return false;
    }
  }
  isChanged(): boolean {
    if (this.isEmailValid() === true) {
    this.setState({isChanged: true})
    return true
    } else
    this.setState({ isChanged: false})
    return false
  }
  isPasswordValid(): boolean {
    //Regular expression for minimum 8 characters, at least one capital letter, one small letter and one number

    const passwordRegExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
    );
    if (passwordRegExp.test(this.user.password)) {
      this.passwordValidation = '';
      this.setState({ isPasswordValid: true });
      this.validateFields();
      return true;
    } else {
      // this.passwordValidation = 'Please enter Valid Password';
      this.setState({ isPasswordValid: false });
      this.setState({ isEnabled: false });
      this.validateFields();
      return false;
    }
  }

  // JSON "get" example

  async findEmailAndToken(
    emailAddress: string,
    token: string
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const authParamaterObject = { emailAddress: emailAddress, token: token };
      await axios
        .post(process.env.REACT_APP_API_CHECK_LOGIN || '', authParamaterObject)
        .then((res) => {
          resolve(true);
        })
        .catch((error) => {
          console.log("Sorry bud can't find the token");
          this.setState({ redirect: '/login' });
          reject(false);
        });
      reject(false);
    });
  }

  async handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (!this.state.isPasswordValid || !this.state.isEmailValid) {
      this.setState({ isFormValid: false, isNotPressed: true });
      this.loginResponse.header = 'Un-authorized';
      this.loginResponse.message = 'Invalid Email Address or Password';
      this.setState({ showMessage: true });
      return;
    }
    this.user.password = sha256(this.user.emailAddress + this.user.password);
    // console.log('sha complete, proceeding to log in');
    this.setState({ isChanged : false, isNotPressed : false, isFormValid: true })
    axios
      .post(process.env.REACT_APP_API_LOGIN || '', this.user)

      .then((response) => {
        this.loginResponse.header = 'Success';
        this.loginResponse.message = 'Logged In successfully';

        let authParameters = {
          isLoggedIn: true,
          emailAddress: this.user.emailAddress,
          token: response.data,
        };
        cookies.set('authParameter', authParameters);

        window.open('/user/dashboard', '_self');
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === HttpStatus.Unauthorized
        ) {
          this.loginResponse.header = 'Un-authorized';
          this.loginResponse.message = 'Invalid Email Address or Password';
        } else if (
          error.response &&
          error.response.status === HttpStatus.BadRequest
        ) {
          this.loginResponse.header = 'Bad Request';
          this.loginResponse.message = 'Required information is not provided';
        } else if (
          error.response &&
          error.response.status === HttpStatus.NotFound
        ) {
          this.loginResponse.header = 'Not Found';
          this.loginResponse.message = 'Required Resource not found';
        } else {
          this.loginResponse.header = 'Unknown Error';
          this.loginResponse.message = 'Unknown Error Occurred';
        }
        this.setState({ showMessage: true, isFormValid: false, isNotPressed: true, isChanged: false });
      });

    this.user.password = '';
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    const { showPassword } = this.state
    return (
      <IonPage id="login-page">
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <HomeNavButtons />
            </IonButtons>
            <IonTitle class="title">Login</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonCardContent class="login-card">
              <IonAlert
                isOpen={this.state.showMessage}
                header={this.loginResponse.header}
                message={this.loginResponse.message}
                buttons={['OK']}
              />
              <div className='login-body'>
                <form onSubmit={(e) => this.handleSubmit(e)} action="post">
                  <IonList>
                    <IonItem>
                      <IonLabel position="floating" color="primary">
                        Email Address
                      </IonLabel>
                      <IonInput
                        name="email"
                        type="email"
                        size={25}
                        value={this.user.emailAddress}
                        spellCheck={false}
                        autocapitalize="off"
                        onIonChange={(e) =>
                          (this.user.emailAddress = e.detail.value!)
                        }
                        onIonBlur={() => this.isEmailValid()}
                        required
                      ></IonInput>
                      <IonNote color="danger">{this.emailValidation}</IonNote>
                    </IonItem>
                   
                   
                    <IonItem className='topSpace'>
                      <IonLabel position='floating' color='primary'>
                        Password
                      </IonLabel>

                      <IonInput
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={this.user.password}
                        onIonChange={(e) =>
                          (this.user.password = e.detail.value!, this.isChanged())
                        }
                        onIonBlur={() => this.isPasswordValid()}
                        required
                      ></IonInput>
                      <IonNote color="danger">
                        {this.passwordValidation}
                      </IonNote>
                      <IonIcon className='eye-toggle' size='large' slot='end' icon={showPassword ? eyeOffOutline : eyeOutline} onClick={() => this.toggleShow()}/>
                    </IonItem>
                   
                  </IonList>

                  <IonRow>
                    <IonCol>
                      <IonButton
                        className='press-me'
                        disabled={!this.state.isChanged}
                        hidden={!this.state.isNotPressed}
                        type="submit"
                        expand="block"
                      >
                        Login
                      </IonButton>
                      <IonButton
                        className='press-me'
                        disabled={true}
                        hidden={this.state.isNotPressed}
                        type="submit"
                        expand="block"
                      >
                        Login
                      </IonButton>
                      <div className='login-confirmation' hidden={this.state.isNotPressed}>Proceeding to log you in ...<IonSpinner color='primary' /></div>
                    </IonCol>
                  </IonRow>

                  <IonList className='fplink'>
                    <a href="/recover" className="link">
                      Forgotten password?
                    </a>
                  </IonList>
                  <IonList className='reglink'>
                    <a href="/register" className="link">
                      Don't have an account?
                    </a>
                  </IonList>
                </form>
              </div>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Login);
