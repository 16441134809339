import React from 'react'

/*
    rect is a rectangle, dy shifts the text away from the vertical lines
    innerHeight + 4 pushes the lables(tickValue) down and away from the verticle lines.
    The title aspect creates a tool tip so you can see the value of each tool tip by hovering
    over it.

*/
export const Marks = ({ data, xScale, yScale, xValue, yValue, tooltipFormat }) =>
  data.map((d) => (
    <rect
      className='mark'
      key={yValue(d)}
      y={yScale(yValue(d))}
      width={xScale(xValue(d))}
      height={yScale.bandwidth()}
    >
      <title>{tooltipFormat(xValue(d))}</title>
    </rect>
  ))