// The xAxis methods
import React from 'react'
/*
    Axis Bottom uses the tickvalues e.g the lables of the graph in csv, maps them
    onto the svg element found in ThirdFetch and places the text in the middle(TextAnchor).
    The lines in the graph are created using the line property
    The tickFormat is used to format the axis label if the number represents 1000's or millions
    without getting too large.
*/
export const AxisBottom = ({ xScale, innerHeight, tickFormat, tickOffset = 9 }) =>
  xScale.ticks().map(tickValue => (
    <g
      className='tick'
      key={tickValue}
      transform={`translate(${xScale(tickValue)},0)`}
    >
      <line y2={innerHeight} />
      <text style={{ textAnchor: 'middle' }} dy='.71em' y={innerHeight + tickOffset}>
        {tickFormat(tickValue)}
      </text>
    </g>
  ))
