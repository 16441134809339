import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from '@ionic/react';
import { Redirect } from 'react-router-dom';
import React, { Component } from 'react';
//import './Home.css';
// import { Link } from 'react-router-dom';

import { HomeNavButtons } from './menu/HomeNavButtons';
import './Settings.css';

export class Settings extends Component {
  state = {
    redirect: '',
    showMessage: false,
    isEnabled: false,
  };


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="dark">
            <IonTitle class="title">Settings</IonTitle>
            <IonButtons slot="start">
              <HomeNavButtons />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonCard>
          <IonCardContent class="settings-card">
            Settings page is developing at the moment!!!
          </IonCardContent>
        </IonCard>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Settings);
