import React from 'react'
import * as d3 from 'd3'

// csv data
const csvUrl = 'https://gist.githubusercontent.com/Metalquill/f90454f5488684487e9ad8a1e7d358df/raw/d85a28569c4656110844d4bad31651b51ff2b0c2/newData.csv'

export const useData = () => {
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    const row = (d) => {
      d.Litres = +d['Reading'] // Creates a new Column called Litres
      // the 'plus' allows the number to be parsed as a float rather than a string
      return d
    }
    d3.csv(csvUrl, row).then((data) => {
      setData(data.slice(12, 25)) // start the data and stop the data flow
    })
  },
  [])
  return data
}
