import React from 'react'
import * as d3 from 'd3'
import './Line.css'
import { IonSpinner, IonText } from '@ionic/react'
import { AxisBottom } from './graphComponents/lineComponents/AxisBottom'
import { Marks } from './graphComponents/lineComponents/Marks'
import { AxisLeft } from './graphComponents/lineComponents/AxisLeft'
import { useData } from './graphComponents/lineComponents/useData'


// variables for width and height
const width = 725
const height = 510
const margin = { top: 20, right: 30, bottom: 65, left: 70 }

// axes variables
const xAxisLabelOffset = 50
const yAxisLabelOffset = 45

// axes variables
const innerHeight = height - margin.top - margin.bottom
const innerWidth = width - margin.left - margin.right


export default function LineThree () {
  const data = useData()
  
  if (!data) {
    return <div className='loading-data'>Loading...<IonSpinner color='primary' /></div>
  }
  // attempted zoom and pan logic
  const svg = d3.select('svg')
  const g = svg.append('g')
  
  svg.call(d3.zoom().on('zoom', (event) => {
    // console.log('zoomed')
    g.attr('transform', event.transform)
  }))

  // x and y Values
  const xValue = d => d.timestamp
  const xAxisLabel = 'Date'

  const yValue = d => d.Litres
  const yAxisLabel = 'Litres'

  // Tickformat which displays the date as a day e.g 13 April => 13
  const xAxisTickFormat = d3.timeFormat('%d')

  // Tickformat which displays the Litres as whole numbers
  const siFormat = d3.format('.1f')
  const yAxisTickFormat = n => siFormat(n)

  // Scaleband for time
  const xScale = d3.scaleTime()
    .domain(d3.extent(data, xValue))
    .range([0, innerWidth])
    .nice()

  // Scale linear constructs a scale with a linear relationship between input and output.
  const yScale = d3.scaleLinear()
    .domain(d3.extent(data, yValue))
    .range([innerHeight, 0])
    .nice()

  // calculating the total water consumption over a month
  // firstly by mapping out each Litre in the array then reducing so the sum of litres is returned
  const monthlyUsage = data.map(sum => (sum.Litres))
  const totalMonthlyUsage = monthlyUsage.reduce((a, b) => a + b, 0)
  

  return (
    <div className='card'>
      <svg className='monthly-graph' width={width} height={height} viewBox='0 0 800 600'>
        <g transform={`translate(${margin.left},${margin.top})`}>
          <AxisBottom
            xScale={xScale}
            innerHeight={innerHeight}
            tickFormat={xAxisTickFormat}
            tickOffset={5}
          />
          <text
            className='axis-label'
            textAnchor='middle'
            transform={`translate(${-yAxisLabelOffset},${innerHeight /
                  2}) rotate(-90)`}
          >
            {yAxisLabel}
          </text>
          <AxisLeft yScale={yScale} innerWidth={innerWidth} tickOffset={5} />
          <text
            className='axis-label'
            x={innerWidth / 2}
            y={innerHeight + xAxisLabelOffset}
            textAnchor='middle'
          >
            {xAxisLabel}
          </text>
          <Marks
            data={data}
            xScale={xScale}
            yScale={yScale}
            xValue={xValue}
            yValue={yValue}
            tooltipFormat={yAxisTickFormat}
            circleRadius={7}
          />
        </g>
      </svg>
        <IonText className='aug-total'>Total Monthly usage for August:<b>{` ${totalMonthlyUsage} Litres `}</b></IonText>
    </div>
  )
}
