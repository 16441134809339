import './Dashboard.css';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonBackButton,
  IonCardTitle,
} from '@ionic/react';
import { Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import Line  from '../graphs/Line'
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Cookies } from 'react-cookie';
import  { IonSlides, IonSlide } from '@ionic/react'

const cookies: Cookies = new Cookies();


const slideOpts = {
  initialSlide: 2,
  speed: 400,
}
export class MonthlyUsage extends Component {
  state = {
    redirect: '',
    day: [],
    index: {},
    monthlyRead: 0,
  };
  private index: number = 0;

  componentDidMount() {
    // getting cookies from
    const authParameter = cookies.get('authParameter');
    if (authParameter !== undefined) {
      let fromDate = new Date('2020/08/15');
      let toDate = new Date('2020/08/30');
      // fromDate = new Date((toDate.getMonth() - 7).toLocaleString());
      this.getSmartMeterDataByDate(
        'METER111',
        '3acc9326a2adeec397c4f83ed0bbac',
        fromDate,
        toDate
      );
    } else {
      this.setState({ redirect: '/login' });
    }
  }

  async getSmartMeterDataByDate(
    meterId: String,
    token: String,
    fromDate: Date,
    toDate: Date
  ) {
    const meterDataParam = {
      meterId: meterId,
      token: token,
      fromDate: fromDate,
      toDate: toDate,
    };
    await axios
      .post(
        process.env.REACT_APP_API_SMART_METER_DATA_BY_DATE || '',
        meterDataParam
      )
      .then((res) => {
        // Need to refine data here
        // let data =res.data.find((x:any)=> { let date=new Date(x.date); let today=new Date(); return x.date == new Date(today.getDate()-7)} )

        const hour = res.data.map((e: any) => e.date.substring(0, 10));

        // const newDate = new Date(hour);
        // console.log(newDate);

        // console.log(hour);
        // console.log(res.data);
        const index = res.data.map((e: any) => {
          return e.index;
        });
        const dailyData = {
          labels: hour,
          datasets: [
            {
              label: 'Monthly Usage',
              data: index,
              backgroundColor: 'green',
              borderColor: 'black',
              borderWidth: 1,
            },
          ],
        };
        // console.log(index);
        this.setState({ index: dailyData });
      });

    this.setState({ monthlyRead: this.index });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>Monthly Usage</IonTitle>
          </IonToolbar>
        </IonHeader>
      
        <IonContent className="ion-padding">
      
          <IonCard class="daily-card">
        
            <IonCardTitle>Monthly water usage</IonCardTitle>
            {/* <IonSlides pager={true} scrollbar= {true} options={slideOpts}> */}
            <IonCardContent>
            {/* <Bar data={this.state.index} options={{}} /> */}
           
              <Line />
           
            </IonCardContent>
            {/* </IonSlides> */}
          </IonCard>
       
        </IonContent>
     
      </IonPage>
    );
  }
}

export default MonthlyUsage;
