// gathering the data
import React from 'react'
import * as d3 from 'd3'

const csvUrl =
  'https://gist.githubusercontent.com/Metalquill/670b675c2f0d8b82c7adf57acee49531/raw/a7ff224a93d48a9706a81d3e52a8903cf318c95f/otherMonthlyData.csv'

export const useData = () => {
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    const row = d => {
      d.Litres = +d['Reading']
      d.timestamp = new Date(d.Timestamp)
      return d
    }
    d3.csv(csvUrl, row).then(setData)
  }, [])

  return data
}
