import {
  IonButtons,
  IonContent,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonBackButton,
  IonText,
  IonHeader,
  IonCardTitle,
} from '@ionic/react';
import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import  Scale  from './graphs/Scale'
import './graphics.css'
import axios from 'axios';
import Join from './graphs/Join'

const cookies: Cookies = new Cookies();


export class Graphics extends Component {
  state = {
    firstName: '',
    lastName: '',
    dob: '',
    homeAddress: '',
    emailAddress: '',
  };
  async getAccountDetail(emailAddress: String, token: String) {
    const userParameter = { emailAddress: emailAddress, token: token };
    await axios
      .post(process.env.REACT_APP_API_ACCOUNT_DETAIL || '', userParameter)
      .then((res) => {
       

        this.setState({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          dob: res.data.dob,
          homeAddress: res.data.houseAddress,
          emailAddress: res.data.emailAddress,
        });
      });
      
  }
  render () {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>Daily</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <IonCard class="daily-card">
            <IonCardTitle className='top-header'>Daily Water Usage</IonCardTitle>
            <IonCardContent className='new-graph'>
            {/* <svg width={width} height={height}>
              {data.map((d: any )=> <rect x={0} y={yScale(d.Reading)} width={} height={}/>)}
            </svg> */}
            <Join />
            {/* <Scale /> */}
            </IonCardContent>
         
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}
export default Graphics

