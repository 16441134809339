import React, { Component, FormEvent } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonNote,
  IonButton,
  IonCard,
  IonCardContent,
  IonAlert,
} from '@ionic/react';
import axios from 'axios';
import sha256 from 'sha256';
import { HomeNavButtons } from '../menu/HomeNavButtons';
import './RecoverPassword.css';

export class RecoverPassword extends Component {
  private user = { emailAddress: '', verificationCode: '', password: '' };
  private recoverPasswordResponse = { header: '', message: '' };
  private passwordValidation: string = '';
  private emailValidation: string = '';
  state = {
    redirect: '',
    showMessage: false,
    isEnabled: false,
    isEmailValid: false,
    isPasswordValid: false,
    message: '',
    success: false,
  };

  validateFields(): any {
    if (
      this.user.emailAddress === '' ||
      this.user.password === '' ||
      !this.state.isEmailValid ||
      !this.state.isPasswordValid
    ) {
      this.setState({ isEnabled: false });
    } else {
      this.setState({ isEnabled: true });
    }
  }
  /*validateFields(): any {
    if (
      this.user.emailAddress === '' ||
      !this.isEmailValid 
    ) {
      this.setState({ isEnabled: false });
    } else {
      this.setState({ isEnabled: true });
    }
  }
*/
  isEmailValid(): boolean {
    //Regular expression for simple email format check
    const emailRegExp = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (emailRegExp.test(this.user.emailAddress)) {
      this.emailValidation = '';
      this.setState({ isEmailValid: true });
      this.validateFields();
      return true;
    } else {
      this.emailValidation = 'Please enter Valid Email Address';
      this.setState({ isEmailValid: false });
      this.setState({ isEnabled: false });
      return false;
    }
  }

  // JSON "get" example
  isPasswordValid(): boolean {
    //Regular expression for minimum 8 characters, at least one capital letter, one small letter and one number

    const passwordRegExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
    );
    if (passwordRegExp.test(this.user.password)) {
      this.passwordValidation = '';
      this.setState({ isPasswordValid: true });
      this.validateFields();
      return true;
    } else {
      this.passwordValidation = 'Please enter Valid Password';
      this.setState({ isPasswordValid: false });
      this.setState({ isEnabled: false });
      return false;
    }
  }

  async handleSubmit(e: FormEvent) {
    e.preventDefault();

    this.user.password = sha256(this.user.emailAddress + this.user.password);
    axios
      .post(process.env.REACT_APP_API_CONFIRM_PASSWORD || '', this.user)
      .then((response) => {
        this.recoverPasswordResponse.header = 'Success';
        this.recoverPasswordResponse.message =
          'Password has been changed succesfully, You can login now';
        this.setState({ success: true });
      })
      .catch((error) => {
        this.recoverPasswordResponse.header = 'Failure';
        this.recoverPasswordResponse.message =
          'Invalid Email Address or Verification code';
        this.setState({ showMessage: true });
      });
    this.user.password = '';
  }
  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <HomeNavButtons />
              <IonBackButton />
            </IonButtons>
            <IonTitle class="recover-title">Forgot Password</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonCardContent class="recoverpassword-card">
              <IonAlert
                isOpen={this.state.success}
                header={this.recoverPasswordResponse.header}
                message={this.recoverPasswordResponse.message}
                buttons={[
                  {
                    text: 'OK',
                    handler: () => {
                      this.setState({ redirect: '/login' });
                    },
                  },
                ]}
              />
              <IonAlert
                isOpen={this.state.showMessage}
                header={this.recoverPasswordResponse.header}
                message={this.recoverPasswordResponse.message}
                buttons={[
                  {
                    text: 'OK',
                    handler: () => {
                      this.setState({ redirect: '/recoverpassword' });
                    },
                  },
                ]}
              />
              <form onSubmit={(e) => this.handleSubmit(e)} action="post">
                <IonItem>
                  <IonLabel position="floating" color="primary">
                    Email Address:
                  </IonLabel>
                  <IonInput
                    name="email"
                    type="email"
                    value={this.user.emailAddress}
                    spellCheck={false}
                    autocapitalize="off"
                    onIonChange={(e) =>
                      (this.user.emailAddress = e.detail.value!)
                    }
                    onIonBlur={() => this.isEmailValid()}
                    required
                  ></IonInput>
                  <IonNote color="danger"> {this.emailValidation} </IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating" color="primary">
                    Verify Code:
                  </IonLabel>
                  <IonInput
                    name="verifyCode"
                    type="text"
                    value={this.user.verificationCode}
                    spellCheck={false}
                    autocapitalize="off"
                    onIonChange={(e) =>
                      (this.user.verificationCode = e.detail.value!)
                    }
                    required
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating" color="primary">
                    New Password
                  </IonLabel>
                  <IonInput
                    type="password"
                    value={this.user.password}
                    spellCheck={false}
                    autocapitalize="off"
                    onIonChange={(e) => (this.user.password = e.detail.value!)}
                    onIonBlur={() => this.isPasswordValid()}
                    required
                  ></IonInput>
                  <IonNote color="danger"> {this.passwordValidation} </IonNote>
                </IonItem>

                <IonButton type="submit" disabled={!this.state.isEnabled}>
                  Submit
                </IonButton>
              </form>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}
export default RecoverPassword;
