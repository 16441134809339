import React from 'react';
import { Route } from 'react-router-dom';
import { IonRouterOutlet, IonPage } from '@ionic/react';
import DashBoard from '../pages/dashboard/Dashboard';
import Settings from '../pages/Settings';

import { Plugins } from '@capacitor/core';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';
import AccountDetail from '../pages/accounts/AccountDetail';
import { DailyUsage } from '../pages/dashboard/DailyUsage';
import MonthlyUsage from '../pages/dashboard/MonthlyUsage';
import { ChangePassword } from '../pages/changePassword/ChangePassword';
import YearlyUsage from '../pages/dashboard/YearlyUsage';
import SeasonalUsage from '../pages/dashboard/SeasonalUsage'


export const { Storage } = Plugins;

const AppTabs: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route path="/user/account" component={AccountDetail} exact={true} />
        <Route
          path="/user/dashboard"
          component={DashBoard}
          exact={true}
        ></Route>

        <Route path="/user/settings" component={Settings} exact={true}></Route>
        <Route
          path="/user/dailyusage"
          component={DailyUsage}
          exact={true}
        ></Route>
         <Route
          path="/user/season"
          component={SeasonalUsage}
          exact={true}
        ></Route>
        <Route
          path="/user/changepassword"
          component={ChangePassword}
          exact={true}
        ></Route>
        <Route
          path="/user/monthlyusage"
          component={MonthlyUsage}
          exact={true}
        ></Route>
        <Route
          path="/user/yearlyusage"
          component={YearlyUsage}
          exact={true}
        ></Route>
      </IonRouterOutlet>

      {/* <IonTabBar slot="bottom">
        <IonTabButton tab="Dashboard" href="/user/dashboard">
          <IonIcon icon={dashboardIcon} />
          <IonLabel>Dashboard</IonLabel>
        </IonTabButton>
        <IonTabButton tab="Setting" href="/user/settings">
          <IonIcon icon={logOut} />
          <IonLabel>Log Out</IonLabel>
        </IonTabButton>
      </IonTabBar> */}
    </IonPage>
  );
};

export default AppTabs;
