// Mark methods which displays the points on the graph

import React from 'react'
import * as d3 from 'd3'

/*
    rect is a rectangle, dy shifts the text away from the vertical lines
    innerHeight + 4 pushes the lables(tickValue) down and away from the verticle lines.
    The title aspect creates a tool tip so you can see the value of each tool tip by hovering
    over it.

*/
// Arrays to give each key a unique value
const myArray = [1, 3, 4, 6, 7, 9, 10, 2, 5, 23, 35, 72, 67, 38]
// const otherArray = [2, 5, 8, 11, 44, 58, 90, 123, 456, 723]

function keys (item, index, arr) {
  arr[index] = item * 10
}
// gives the Marks a unique key
const newKeys = myArray.forEach(keys)
// const otherKeys = otherArray.forEach(keys)

export const Marks = ({
    data,
    xScale,
    yScale,
    xValue,
    yValue,
    tooltipFormat,
    circleRadius,
    tickValue
  }) => (
    <g className='marks'>
      <path
        key={Math.random(newKeys)}
        overflow='scroll'
        fill='none'
        stroke='black'
        d={d3.line()
          .x(d => xScale(xValue(d)))
          .y(d => yScale(yValue(d)))
          .curve(d3.curveNatural)(data)}
      />
      {data.map(d => (
        <circle
          key={tickValue}
          cx={xScale(xValue(d))}
          cy={yScale(yValue(d))}
          r={circleRadius}
        >
          <title>{tooltipFormat(yValue(d))}</title>
        </circle>
      ))}
    </g>
  )