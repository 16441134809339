import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
  IonButtons,
} from '@ionic/react';
import { Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import { UserNavButtons } from './menu/UserNavButtons'
import Water from './img/water.jpg'
import './Settings.css'
//import './Home.css';
// import { Link } from 'react-router-dom';
export class NotFound extends Component {
  state = {
    redirect: '',
    showMessage: false,
    isEnabled: false,
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar mode="ios" color="dark">
            <IonButtons slot="start">
              <UserNavButtons />
            </IonButtons>
            <IonTitle class="dashboard-title">Flow Watch</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
        <h1 className='fourOfour'><b>404</b></h1>
          <div className='img-div1'>
            <img className='water' src={Water} alt='water' />
          </div>

          <h1 className='fourOfour'><b>Page Not Found!</b></h1>
          <IonHeader collapse="condense">
          </IonHeader>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(NotFound);
