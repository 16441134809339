import React, { Component, FormEvent } from 'react';
import { Redirect } from 'react-router-dom';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonNote,
  IonButton,
  IonCard,
  IonCardContent,
  IonAlert,
} from '@ionic/react';
import axios from 'axios';
import sha256 from 'sha256';
import { HomeNavButtons } from '../menu/HomeNavButtons';
import './ChangePassword.css';
import { HelperFunctions } from '../../helper/helperFunction';
import { Cookies } from 'react-cookie';

const cookies: Cookies = new Cookies();

const helperFunction: HelperFunctions = new HelperFunctions();

export class ChangePassword extends Component {
  private user = { token: '', oldPassword: '', newPassword: '' };
  private changePasswordResponse = { header: '', message: '' };
  private confirmPassword: string = '';
  private oldPasswordValidation: string = '';
  private newPasswordValidation: string = '';
  private emailAddress: string = '';
  private passwordMatch: string = '';
  private token: string = '';

  state = {
    redirect: '',
    showMessage: false,
    isEnabled: false,
    isOldPasswordValid: false,
    isNewPasswordValid: false,
    isPasswordMatch: false,
    success: false,
  };

  componentDidMount() {
    // getting cookies from
    const authParameter = cookies.get('authParameter');
    if (authParameter !== undefined) {
      this.emailAddress = authParameter.emailAddress;
      this.token = authParameter.token;
    } else {
      this.setState({ redirect: '/login' });
    }
  }

  isPasswordMatch(): boolean {
    // Checks to see if password and confirm password are equal
    if (!this.user.newPassword) {
      this.passwordMatch = 'Please enter a valid Password';
      this.setState({ isPasswordValid: false });
      this.setState({ isEnabled: false });
      return false;
    } else {
      if (this.user.newPassword === this.confirmPassword) {
        this.passwordMatch = '';
        this.setState({ isPasswordMatch: true });
        this.validateFields();
        return true;
      } else {
        this.setState({ isPasswordMatch: false });
        this.setState({ isEnabled: false });
        this.setState({
          passwordMatch: 'New Password and Repeat Password do not match',
        });
        return false;
      }
    }
  }

  isOldPasswordValid(): boolean {
    //Regular expression for minimum 8 characters, at least one capital letter, one small letter and one number
    this.confirmPassword = '';
    this.setState({ isPasswordMatch: false });
    if (!this.user.oldPassword) {
      this.oldPasswordValidation = 'Please enter a valid Password';
      this.setState({ isOldPasswordValid: false });
      this.setState({ isEnabled: false });
      return false;
    } else {
      if (helperFunction.isPasswordValid(this.user.oldPassword)) {
        this.oldPasswordValidation = '';
        this.setState({ isOldPasswordValid: true });
        this.validateFields();
        return true;
      } else {
        this.oldPasswordValidation = 'Please enter a valid Password';
        this.setState({ isOldPasswordValid: false });
        this.setState({ isEnabled: false });
        return false;
      }
    }
  }
  isNewPasswordValid(): boolean {
    //Regular expression for minimum 8 characters, at least one capital letter, one small letter and one number
    this.confirmPassword = '';
    this.setState({ isPasswordMatch: false });
    if (!this.user.newPassword) {
      this.newPasswordValidation = 'Please enter a valid Password';
      this.setState({ isNewPasswordValid: false });
      this.setState({ isEnabled: false });
      return false;
    } else {
      if (helperFunction.isPasswordValid(this.user.oldPassword)) {
        this.newPasswordValidation = '';
        this.setState({ isNewPasswordValid: true });
        this.validateFields();
        return true;
      } else {
        this.newPasswordValidation = 'Please enter a valid Password';
        this.setState({ isNewPasswordValid: false });
        this.setState({ isEnabled: false });
        return false;
      }
    }
  }

  validateFields(): any {
    if (
      !this.state.isPasswordMatch ||
      !this.state.isNewPasswordValid ||
      !this.state.isOldPasswordValid
    ) {
      this.setState({ isEnabled: false });
    } else {
      this.setState({ isEnabled: true });
    }
  }

  async handleSubmit(e: FormEvent) {
    e.preventDefault();

    this.user.token = this.token;
    this.user.oldPassword = sha256(this.emailAddress + this.user.oldPassword);
    this.user.newPassword = sha256(this.emailAddress + this.user.newPassword);
    axios
      .post(process.env.REACT_APP_API_CHANGE_PASSWORD || '', this.user)
      .then((response) => {
        this.changePasswordResponse.header = 'Success';
        this.changePasswordResponse.message =
          'Password has been changed succesfully';
        this.setState({ success: true });
      })
      .catch((error) => {
        this.changePasswordResponse.header = 'Failure';
        this.changePasswordResponse.message = 'Something wrong happened';
        this.setState({ showMessage: true });
      });
    this.user.newPassword = '';
    this.user.oldPassword = '';
    this.confirmPassword = '';
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <HomeNavButtons />
              <IonBackButton />
            </IonButtons>
            <IonTitle class="change-password-title">Change Password</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className='container'>
          <IonCard className='ch-pss-card'>
            <IonCardContent class="change-password-card">
              <IonAlert
                isOpen={this.state.success}
                header={this.changePasswordResponse.header}
                message={this.changePasswordResponse.message}
                buttons={[
                  {
                    text: 'OK',
                    handler: () => {
                      this.setState({ redirect: '/login' });
                    },
                  },
                ]}
              />
              <IonAlert
                isOpen={this.state.showMessage}
                header={this.changePasswordResponse.header}
                message={this.changePasswordResponse.message}
                buttons={[
                  {
                    text: 'OK',
                    handler: () => {
                      this.setState({ redirect: '/login' });
                    },
                  },
                ]}
              />
              <form onSubmit={(e) => this.handleSubmit(e)} action="post">
                <IonItem>
                  <IonLabel position="floating" color="primary">
                    Old Password:
                  </IonLabel>
                  <IonInput
                    name="password"
                    type="password"
                    value={this.user.oldPassword}
                    spellCheck={false}
                    autocapitalize="off"
                    onIonChange={(e) =>
                      (this.user.oldPassword = e.detail.value!)
                    }
                    onIonBlur={() => this.isOldPasswordValid()}
                    required
                  ></IonInput>
                  <IonNote color="danger">
                    {' '}
                    {this.oldPasswordValidation}{' '}
                  </IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating" color="primary">
                    New Password:
                  </IonLabel>
                  <IonInput
                    name="password"
                    type="password"
                    value={this.user.newPassword}
                    spellCheck={false}
                    autocapitalize="off"
                    onIonChange={(e) =>
                      (this.user.newPassword = e.detail.value!)
                    }
                    onIonBlur={() => this.isNewPasswordValid()}
                    required
                  ></IonInput>
                  <IonNote color="danger">
                    {' '}
                    {this.newPasswordValidation}{' '}
                  </IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating" color="primary">
                    Confirm Password:
                  </IonLabel>
                  <IonInput
                    name="confirmPassword"
                    type="password"
                    value={this.confirmPassword}
                    spellCheck={false}
                    autocapitalize="off"
                    onIonChange={(e) =>
                      (this.confirmPassword = e.detail.value!)
                    }
                    onIonBlur={() => this.isPasswordMatch()}
                    required
                  ></IonInput>
                </IonItem>

                <IonButton className='change-the-password' type="submit" disabled={!this.state.isEnabled}>
                  Submit
                </IonButton>
              </form>
            </IonCardContent>
          </IonCard>
        </div>
        </IonContent>
      </IonPage>
    );
  }
}
export default ChangePassword;
