import React, { Component } from 'react'
import './Dashboard.css'
import './YearlyUsage.css'
import axios from 'axios'
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonButton,
  IonBackButton,
  IonCardTitle,
  IonSpinner,
  IonAlert,
  IonText
} from '@ionic/react'
import { AxisBottom } from '../graphs/graphComponents/AxisBottom'
import { AxisLeft } from './yearlyGraphComponents/AxisLeft'
import { Cookies } from 'react-cookie'
import { Marks } from '../graphs/graphComponents/Marks'
import { Plugins } from '@capacitor/core'
import { Redirect } from 'react-router-dom'
import { stringToDate } from './yearlyGraphComponents/DateChanger'
import * as d3 from 'd3'

// pulls through cookies which allows us to check the meterID and display usage from that MeterID
const cookies: Cookies = new Cookies();

// variables for width and height for D3 grpahs
const width = 320
const height = 340
const margin = { top: 30, right: 15, bottom: 60, left: 60 }

// axes variables
const innerHeight = height - margin.top - margin.bottom
const innerWidth = width - margin.left - margin.right

// x and y Values
const yValue = (d: {newDate: any}) => d.newDate
const xValue = (d: {newData: any}) => d.newData

// Formatting tick Labels for xAxis, changes the format so it now presents thousands with 'k's
const siFormat = d3.format('~s')
const xAxisTickFormat = (n:number) => siFormat(n)

// Formatting tick Lables for the yAxis
const tiFormat = d3.timeFormat('%d')
const yAxisTickFormat = (k:Date) => tiFormat(k)

export const { Storage } = Plugins;

// use only when T is an object/class instance not with T as a primitive
// Generics (javascript)
function guardArrayClean<T>(arr: (T|undefined)[]): T[] {
  return arr.filter(i => !!i) as T[];
}
export class YearlyUsage extends Component {
  state = {
    redirect: '',
    day: [],
    index: {},
    monthlyRead: 0,
    items: [],
    loading: false,
    reading: [],
    prevReading: [],
    data: [],
    is2015: true,
    is2016: true,
    is2017: true,
    is2018: true,
    is2019: false,
    is2020: true,
    showMessage: true,
    deflect: '',
  };
  private index: number = 0;

  constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this)
    this.handleSecClick = this.handleSecClick.bind(this)
    this.handleThirdClick = this.handleThirdClick.bind(this)
    this.handleForthClick = this.handleForthClick.bind(this)
    this.handleFifthClick = this.handleFifthClick.bind(this)
    this.getManualData();

    // retrieves meterId data from api within the date parameters 
    this.getObject()
      .then((authParam) => {
        if (!authParam) {
          this.setState({ redirect: '/login' });
        } else {
          let fromDate = new Date('2020/08/01');
          let toDate = new Date('2020/08/30');
          fromDate = new Date((toDate.getMonth() - 7).toLocaleString());
          console.log('Iam getting manual data');
          this.getSmartMeterDataByDate(
            'METER111',
            '3acc9326a2adeec397c4f83ed0bbac',
            fromDate,
            toDate
          );
        }
      })
      .catch(() => {
        this.setState({ redirect: '/login' });
      });
  }
// ----------------------------------------Clicks---------------------------------------------------

// switch to 2015
// changes hidden from true to false when 2015 button is clicked
  handleClick = () => {
    this.setState({
      is2015: false,
      is2016: true,
      is2017: true,
      is2018: true,
      is2019: true,
    })
  }
  // switch to 2016
  handleSecClick = () => {
    this.setState({
      is2015: true,
      is2016: false,
      is2017: true,
      is2018: true,
      is2019: true,
    })
  }
  // switch to 2017
  handleThirdClick = () => {
    this.setState({
      is2015: true,
      is2016: true,
      is2017: false,
      is2018: true,
      is2019: true,
    })
  }
  // switch to 2018
  handleForthClick = () => {
    this.setState({
      is2015: true,
      is2016: true,
      is2017: true,
      is2018: false,
      is2019: true,
    })
  }
  // switch to 2019
  handleFifthClick = () => {
    this.setState({
      is2015: true,
      is2016: true,
      is2017: true,
      is2018: true,
      is2019: false,
    })
  }
  // switch to 2020 some data doesn't have a 2020
  handleSixClick = () => {
    this.setState({
      is2015: true,
      is2016: true,
      is2017: false,
      is2018: true,
      is2019: true,
    })
  }
// ----------------------------------------Clicks---------------------------------------------------

// ---------------------------------------Functions--------------------------------------------------
  async getSmartMeterDataByDate(
    meterId: String,
    token: String,
    fromDate: Date,
    toDate: Date
  ) {
    const meterDataParam = {
      meterId: meterId,
      token: token,
      fromDate: fromDate,
      toDate: toDate,
    };
    await axios
      .post(
        process.env.REACT_APP_API_SMART_METER_DATA_BY_DATE || '',
        meterDataParam
      )
      .then((res) => {
        const hour = res.data.map((e: any) => e.date.substring(0, 10));
        const index = res.data.map((e: any) => {
          return e.index;
        });
        const dailyData = {
          labels: hour,
          datasets: [
            {
              label: 'Monthly Usage',
              data: index,
              backgroundColor: 'green',
              borderColor: 'black',
              borderWidth: 1,
            },
          ],
        };
        this.setState({ index: dailyData });
      });
    this.setState({ monthlyRead: this.index });
  }
// Checks to see if inputted meter Id exists within the database
  getManualData = async () => {
    let userMeterId= cookies.get('meterId');
    if (userMeterId!=="") {
      const meterId = { meterId: userMeterId };
      await axios
        .post(
          process.env.REACT_APP_API_MANUAL_READING || '',
          meterId
        )
        .then((res) => {
          //console.log(`testing res: ${JSON.stringify(res.data)}`)
          this.setState({ 
            items: res.data, 
            loading: true, 
          })
           return true;
    } )
        .catch((error) => {
          console.log(`${error.response}`)
          console.log(error)
          console.log('data has not been received')
          return false;
        });
    }
    return this.state.items
  };
  async getObject() {
    const authParameter = await Storage.get({ key: 'authParameter' });
    if (authParameter) {
      return JSON.parse(authParameter.value!);
    } else {
      this.setState({ redirect: '/login' });
    }
  }
  async clear() {
    await Storage.clear();
  }
  // ---------------------------------------Functions-------------------------------------------------------------------
  render() {
    // destructed state to lift state into the render
    const {loading, items } = this.state
    // checks if the data is loaded if its not loaded it returns a spinner until loaded
   if (!loading) {
    return <div className='loading-spinner'>Please wait I'm Loading...<IonSpinner color='primary' /></div>
   } 
   // deflects (redirects) when needed 
   if (this.state.deflect) {
    return <Redirect to={this.state.deflect} />;
  }
  // if the data (items) has less than one entry in the array a message will pop up
  // and redirect the user
   else if (loading === true && items.length < 1) {
    console.log(`output of items: ${JSON.stringify(items)}`) 
    return  <IonAlert
     isOpen={this.state.showMessage}
     header={"I'm sorry, Dave. I'm afraid I can't do that "}
     message={
       'There is a problem retrieving your data\n press OK to be redirected'
     }
     buttons={[
       {
         text: 'OK',
         handler: () => {
           this.setState({ deflect: '/user/dashboard' });
         },
       },
     ]}
   />

   } else {
     
// ------------------------------------------------------DATA-----------------------------------------------------------

    // 2015 Data
    // creates a new row called newData which is the newReading - previousReading and multiplying that value by 1000
    // creates a new row called newDate which is then transfered to the local date and from there the month is displayed
    const mapData = guardArrayClean(items.map((accs : {previousReading: any, newReading: any, newData: Number, readingDate: any, newDate: any, order: number }) => {
      if (accs.previousReading === '0'){
        accs.previousReading = accs.newReading
      }
      if (accs.readingDate.includes('2015') === true) {
        const newDate = stringToDate(accs.readingDate,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{month: 'short',})
        accs.newDate = newDate
        const newData = accs.newReading - accs.previousReading;
        accs.newData = newData*1000;
        return accs
      }
      return
    }));
    
    // 2016 Data
    const mapData1 =  guardArrayClean(items.map((accs : {previousReading: any, newReading: any, newData: Number, readingDate: any, newDate: any, order: number }) => {
      if (accs.previousReading === '0'){
        accs.previousReading = accs.newReading
      }
      if (accs.readingDate.includes('2016') === true) {
        const newDate = stringToDate(accs.readingDate,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{month: 'short',})
        accs.newDate = newDate
        const newData = accs.newReading - accs.previousReading;
        accs.newData = newData*1000;
        return accs
      }
      return
    }));

    // 2017 Data
    const mapData2 =  guardArrayClean(items.map((accs : {previousReading: any, newReading: any, newData: Number, readingDate: any, newDate: any, order: number }) => {
      if (accs.previousReading === '0'){
        accs.previousReading = accs.newReading
      }
      if (accs.readingDate.includes('2017') === true) {
        const newDate = stringToDate(accs.readingDate,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{month: 'short',})
        accs.newDate = newDate
        const newData = accs.newReading - accs.previousReading;
        accs.newData = newData*1000;
        return accs
      }
      return
    }));

    // 2018 
    const mapData3 =  guardArrayClean(items.map((accs : {previousReading: any, newReading: any, newData: Number, readingDate: any, newDate: any, order: number}) => {
      if (accs.previousReading === '0'){
        accs.previousReading = accs.newReading
      }
      if (accs.readingDate.includes('2018') === true) {
        const newDate = stringToDate(accs.readingDate,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{month: 'short',})
        accs.newDate = newDate
        const newData = accs.newReading - accs.previousReading;
        accs.newData = newData*1000;
        return accs
      }
      return
    }));

    // 2019
    const mapData4 =  guardArrayClean(items.map((accs : {previousReading: any, newReading: any, newData: Number, readingDate: any, newDate: any, order: number }) => {
      if (accs.previousReading === '0'){
        accs.previousReading = accs.newReading
      }
      if (accs.readingDate.includes('2019') === true) {
        const newDate = stringToDate(accs.readingDate,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{month: 'short',})
        accs.newDate = newDate
        const newData = accs.newReading - accs.previousReading;
        accs.newData = newData*1000;
        return accs
      }
      return
    }));
  // sorting the data by order so in the event it gets jumbled it will still display correctly
  const map2015 = mapData.sort( function (a, b) { return a.order - b.order})
  const map2016 = mapData1.sort( function (a, b) { return a.order - b.order})
  const map2017 = mapData2.sort( function (a, b) { return a.order - b.order})
  const map2018 = mapData3.sort( function (a, b) { return a.order - b.order})
  const map2019 = mapData4.sort( function (a, b) { return a.order - b.order})
    // // 2020
    // const mapData5 =  this.state.item5.map((accs : {previousReading: any, newReading: any, newData: Number, readingDate: any, newDate: any }) => {
    //   if (accs.previousReading === '0'){
    //     accs.previousReading = accs.newReading
    //   }
    //   const newDate = stringToDate(accs.readingDate,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{month: 'short',})
    //   accs.newDate = newDate
    //   const newData = accs.newReading - accs.previousReading;
    //   accs.newData = newData*1000;
    //   return accs;
    // });

  // --------------------------------------------------------DATA----------------------------------------------------------
    
  // --------------------------------------------------------YScale---------------------------------------------------------

  // mapping out the numbers for the vertical part of the graph

  // 2015
  const yScale = d3.scaleBand()
  .domain(map2015.map(yValue))
  .range([0, innerHeight])
  .paddingInner(0.1)

  // 2016
  const yScale1 = d3.scaleBand()
  .domain(map2016.map(yValue))
  .range([0, innerHeight])
  .paddingInner(0.1)

  // 2017
  const yScale2 = d3.scaleBand()
  .domain(map2017.map(yValue))
  .range([0, innerHeight])
  .paddingInner(0.1)

  // 2018
  const yScale3 = d3.scaleBand()
  .domain(map2018.map(yValue))
  .range([0, innerHeight])
  .paddingInner(0.1)

  // 2019
  const yScale4 = d3.scaleBand()
  .domain(map2019.map(yValue))
  .range([0, innerHeight])
  .paddingInner(0.1)

  // // 2020
  // const yScale5 = d3.scaleBand()
  // .domain(mapData5.map(yValue))
  // .range([0, innerHeight])
  // .paddingInner(0.1)

// --------------------------------------------------------YScale-----------------------------------------------------

// --------------------------------------------------------XScale-----------------------------------------------------

//mapping out the numbers for the horizontal part of the graph

// 2015
const xScale = d3.scaleLinear()
  .domain([0, d3.max(map2015, xValue)])
  .range([0, innerWidth])

// 2016
const xScale1 = d3.scaleLinear()
  .domain([0, d3.max(map2016, xValue)])
  .range([0, innerWidth])

// 2017
const xScale2 = d3.scaleLinear()
  .domain([0, d3.max(map2017, xValue)])
  .range([0, innerWidth])

// 2018
const xScale3 = d3.scaleLinear()
  .domain([0, d3.max(map2018, xValue)])
  .range([0, innerWidth])

// 2019
const xScale4 = d3.scaleLinear()
  .domain([0, d3.max(map2019, xValue)])
  .range([0, innerWidth])

// // 2020
// const xScale5 = d3.scaleLinear()
//   .domain([0, d3.max(mapData5, xValue)])
//   .range([0, innerWidth])


// --------------------------------------------------------XScale-----------------------------------------------------

// ---------------------------------------------------------Years-----------------------------------------------------

// 2015
// pulls out the reading date (month) value from mapData
const yearData = mapData.map((c) : {readingDate: any} => (c.readingDate))
// converts that month value into a string at position 1
const yr1 = yearData[1].toString()
// uses the stringToDate function and the toLocaleDateString to return the year
const year = stringToDate(yr1,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{year: 'numeric',})

// 2016
const yearData1 = mapData1.map((c) : {readingDate: any} => (c.readingDate))
const yr2 = yearData1[1].toString()
const year1 = stringToDate(yr2,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{year: 'numeric',})

// 2017
const yearData2 = mapData2.map((c) : {readingDate: any} => (c.readingDate))
const yr3 = yearData2[1].toString()
const year3 = stringToDate(yr3,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{year: 'numeric',})

// 2018
const yearData3 = mapData3.map((c) : {readingDate: any} => (c.readingDate))
const yr4 = yearData3[1].toString()
const year4 = stringToDate(yr4,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{year: 'numeric',})

// 2019
const yearData4 = mapData4.map((c) : {readingDate: any} => (c.readingDate))
const yr5 = yearData4[1].toString()
const year5 = stringToDate(yr5,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{year: 'numeric',})

// // 2020
// const yearData5 = mapData5.map((c) : {readingDate: any} => (c.readingDate))
// const yr6 = yearData5[1].toString()
// const year6 = stringToDate(yr6,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{year: 'numeric',})

// ---------------------------------------------------------Years-----------------------------------------------------

// ---------------------------------------------------------Totals----------------------------------------------------

// first year
// pulling out newData from mapData
const yearReading = mapData.map((a : {newData: any}) => (a.newData))
// using the reduce function to get the sum of all numbers in the array
const yearTotal = yearReading.reduce((a, b) => a + b, 0)
// creating a blank array as foreach doesn't explicitly return anything
const firstYearLitreArray: any = []
// pushing newData into the new array to be processed
mapData.forEach((j : {newData: any}) => { firstYearLitreArray.push(j.newData) })
// calculating the max value of data in the array by converting to a number and using the .max function
const firstYearLitres = Math.max(...firstYearLitreArray)

// second year
const year1Reading = mapData1.map((a : {newData: any}) => (a.newData))
const year1Total = year1Reading.reduce((a, b) => a + b, 0)
const secondYearLitreArray: any = []
mapData1.forEach((j : {newData: any}) => { secondYearLitreArray.push(j.newData) })
const secondYearLitres = Math.max(...secondYearLitreArray)

// third year
const year2Reading = mapData2.map((a : {newData: any}) => (a.newData))
const year2Total = year2Reading.reduce((a, b) => a + b, 0)
const thirdYearLitreArray: any = []
mapData2.forEach((j : {newData: any}) => { thirdYearLitreArray.push(j.newData) })
const thirdYearLitres = Math.max(...thirdYearLitreArray)

// forth year
const year3Reading = mapData3.map((a : {newData: any}) => (a.newData))
const year3Total = year3Reading.reduce((a, b) => a + b, 0)
const forthYearLitreArray: any = []
mapData3.forEach((j : {newData: any}) => { forthYearLitreArray.push(j.newData) })
const forthYearLitres = Math.max(...forthYearLitreArray)


// fifth year
const year4Reading = mapData4.map((a : {newData: any}) => (a.newData))
const year4Total = year4Reading.reduce((a, b) => a + b, 0)
const fifthYearLitreArray: any = []
mapData4.forEach((j : {newData: any}) => { fifthYearLitreArray.push(j.newData) })
const fifthYearLitres = Math.max(...fifthYearLitreArray)

// // sixth year
// const year5Reading = mapData5.map((a : {newData: any}) => (a.newData))
// const year5Total = year5Reading.reduce((a, b) => a + b, 0)
// const sixthYearLitreArray: any = []
// mapData5.forEach((j : {newData: any}) => { sixthYearLitreArray.push(j.newData) })
// const sixthYearLitres = Math.max(...sixthYearLitreArray)

// ---------------------------------------------------------Totals----------------------------------------------------
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>Yearly Usage</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonCard className='yearly-card'>
          <IonCardTitle className='yearly-water-usage'>Yearly Water Usage</IonCardTitle>
            <IonCardContent>
              <div className='numScale'>
              <IonButton className='year-btn' onClick={() => {this.handleClick()}} disabled={!this.state.is2015}>2015</IonButton><IonButton className='yearly-btn' onClick={() => {this.handleSecClick()}} disabled={!this.state.is2016}>2016</IonButton><IonButton className='yearly-btn' onClick={() => { this.handleThirdClick()} } disabled={!this.state.is2017}>2017</IonButton><IonButton className='yearly-btn' onClick={() => { this.handleForthClick()}} disabled={!this.state.is2018}>2018</IonButton><IonButton className='yearly-btn' onClick={() => { this.handleFifthClick() }} disabled={!this.state.is2019}>2019</IonButton>
                <div className='card' hidden={this.state.is2015}>
                  <svg className='svg-element' width={width} height={height}>
                      <g transform={`translate(${margin.left},${margin.top})`}>
                        <AxisBottom xScale={xScale} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
                        <AxisLeft yScale={yScale} tickFormat={yAxisTickFormat}/>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 265}>Yearly Usage {year} </text>
                        <Marks data={map2015} xScale={xScale} yScale={yScale} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
                      </g>
                    </svg>
                    <p>Yearly usage <b>{Math.round(yearTotal)}</b> Litres</p>
                    <p>Maximum usage <b>{Math.round(firstYearLitres)} Litres</b></p>
                  </div>
                  <div className='card' hidden={this.state.is2016}>
                  <svg className='svg-element' width={width} height={height}>
                      <g transform={`translate(${margin.left},${margin.top})`}>
                        <AxisBottom xScale={xScale1} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
                        <AxisLeft yScale={yScale1} tickFormat={yAxisTickFormat}/>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 265}>Yearly Usage {year1} </text>
                        <Marks data={map2016} xScale={xScale1} yScale={yScale1} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
                      </g>
                    </svg>
                    <p>Yearly usage <b>{Math.round(year1Total)}</b> Litres</p>
                    <p>Maximum usage <b>{Math.round(secondYearLitres)} Litres</b></p>
                  </div>
                  <div className='card' hidden={this.state.is2017}>
                  <svg className='svg-element' width={width} height={height}>
                      <g transform={`translate(${margin.left},${margin.top})`}>
                        <AxisBottom xScale={xScale2} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
                        <AxisLeft yScale={yScale2} tickFormat={yAxisTickFormat}/>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 265}>Yearly Usage {year3} </text>
                        <Marks data={map2017} xScale={xScale2} yScale={yScale2} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
                      </g>
                    </svg>
                    <p>Yearly usage <b>{Math.round(year2Total)}</b> Litres</p>
                    <p>Maximum usage <b>{Math.round(thirdYearLitres)} Litres</b></p>
                  </div>
                  <div className='card' hidden={this.state.is2018}>
                  <svg className='svg-element' width={width} height={height}>
                      <g transform={`translate(${margin.left},${margin.top})`}>
                        <AxisBottom xScale={xScale3} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
                        <AxisLeft yScale={yScale3} tickFormat={yAxisTickFormat}/>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text> ,
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 265}>Yearly Usage {year4} </text>
                        <Marks data={map2018} xScale={xScale3} yScale={yScale3} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
                      </g>
                    </svg>
                    <p>Yearly usage <b>{Math.round(year3Total)}</b> Litres</p>
                    <p>Maximum usage <b>{Math.round(forthYearLitres)} Litres</b></p>
                  </div>
                  <div className='card' hidden={this.state.is2019}>
                  <svg className='svg-element' width={width} height={height}>
                      <g transform={`translate(${margin.left},${margin.top})`}>
                        <AxisBottom xScale={xScale4} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
                        <AxisLeft yScale={yScale4} tickFormat={yAxisTickFormat}/>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 265}>Yearly Usage {year5} </text>
                        <Marks data={map2019} xScale={xScale4} yScale={yScale4} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
                      </g>
                    </svg>
                    <p>Yearly usage <b>{Math.round(year4Total)}</b> Litres</p>
                    <p>Maximum usage <b>{Math.round(fifthYearLitres)} Litres</b></p>
                  </div>
                  {/* <div className='card' hidden={this.state.isDev2015}>
                  <svg className='svg-element' width={width} height={height}>
                      <g transform={`translate(${margin.left},${margin.top})`}>
                        <AxisBottom xScale={xScale5} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
                        <AxisLeft yScale={yScale5} tickFormat={yAxisTickFormat}/>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 282}>Yearly Usage {year6} </text>
                        <Marks data={mapData5} xScale={xScale5} yScale={yScale5} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
                      </g>
                    </svg>
                    <p>Yearly usage <b>{Math.round(year5Total)}</b> Litres</p>
                    <p>Maximum usage <b>{Math.round(sixthYearLitres)} Litres</b></p>
                  </div> */}
                  <IonText className='foot-text'>Usage by season? Click below</IonText>
                  <p></p>
                  <IonButton className='bottom-btn' onClick={() => {this.setState({ deflect: '/user/season' })}}>Season</IonButton>
              </div> 
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}
}

export default YearlyUsage;
