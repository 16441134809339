import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
  IonText,
} from '@ionic/react';
import { Redirect } from 'react-router-dom';
import React, { Component } from 'react';
//import './Home.css';
// import { Link } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import { HomeNavButtons } from './menu/HomeNavButtons';
import './About.css';
import Drop from './img/drop.jpg'

export const { Storage } = Plugins;
export class About extends Component {
  state = {
    redirect: '',
    showMessage: false,
    isEnabled: false,
  };



  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="dark">
            <IonTitle class="title">About</IonTitle>
            <IonButtons slot="start">
              <HomeNavButtons />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
     
        <IonCard className='the-about-card'>
          <div className='img-div'>
            <img className='drop' src={Drop} alt='drop' />
          </div>
          <IonCardContent class="about-card">
          <IonText 
            className='top'
          >
            <b className='bold-text'>H</b>ere at Flow Watch LTD we are here to give water users the ability 
	          to see how much water they are using in order for them to use water more sustainably.
          </IonText>
          <br></br>
          <p className='white-text'>I am a space</p>
          <IonText 
            className='middle'
          >
            <b className='bold-text'>C</b>urrently the city water infrustructure cannot cope with the influx of water use over the summer
	          period and the council has proposed to charge the top 20% of water users who are using 
	          over their allocation, majority of people do not know what their allocation of water is.
          </IonText>
          <p className='white-text'>I am a space</p>
          
          <IonText
          className='bottom'
          >
            <b className='bold-text'>T</b>he solution is to give the users the ability to see how
            much water they are using, by day, month and year. By looking at how much water is used
            and when it is being used we hope that water users can save water and ultimately save money
            in the long run.
          </IonText>
          </IonCardContent>
         
        </IonCard>
    
      </IonPage>
    );
  }
}

export default withIonLifeCycle(About);
