import React from 'react'

/*  dy shifts the text away from the vertical lines
      innerHeight + 4 pushes the lables(tickValue) down and away from the verticle lines
      Axis left uses the tickvalues e.g the lables of the graph (vertically) in csv, maps them
      onto the svg element found in ThirdFetch and places the text in the end space(TextAnchor).
      requires an unique key element in this case its the 'tickValue'. Domain() will always take
      the default value given for Domain. The x property is -4 as it pulls out the lable towards the left
  */

export const AxisLeft = ({ yScale, tickFormat }) =>
  yScale.domain().map(tickValue => (
    <text
      className='left-axis'
      key={tickValue}
      dy='.32em'
      y={yScale(tickValue) + yScale.bandwidth() / 2}
      x={-4}
      style={{ textAnchor: 'end' }}
    >
      {tickValue}
    </text>
))