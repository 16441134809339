import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  IonGrid,
} from '@ionic/react';

import React, { useEffect } from 'react';
import logo from '../Logo/FWLogo.png';
import logoTwo from '../Logo/FWLogoNoWords.png';
import './Home.css';
import { HomeNavButtons } from '../menu/HomeNavButtons';



type Item = {
  src: string;
  text: string;
};

const Home: React.FC = () => {
  useEffect(() => {
    return () => {
    };
  });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios" color="dark">
          <IonTitle className='title'>Flow Watch</IonTitle>
          <IonButtons slot="start">
            <HomeNavButtons />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='home-content'>
        <IonGrid>
          <IonRow>
            <IonCol>

            </IonCol>
            <IonCol>
              
                <div className='center-stuff'>
                 
                      <IonImg className="logo" src={logo} />
                      <div className='blank-space'>This is a space</div>
                      <div className='blank-space'>This is a space</div>
                      <IonButton
                        className='home-login-btn'
                        slot="end"
                        routerLink="/login"
                        type="submit"
                        expand="block"
                      >
                        Login
                      </IonButton>

                      <a href="/register" className="link">
                        Don't have an account?
                      </a>
                
                </div>
             
            </IonCol>
            <IonCol>

            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
