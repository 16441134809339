import React, { FormEvent } from 'react';
import { Redirect } from 'react-router-dom';
import {
  IonHeader,
  IonAlert,
  IonToolbar,
  IonNote,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonDatetime,
  withIonLifeCycle,
  IonCard,
  IonCardContent,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { User } from '@flow-watch/model/user';
import axios from 'axios';
import sha256 from 'sha256';
import { HttpStatus } from '@flow-watch/model/httpStatus';
import { Plugins } from '@capacitor/core';
import { HomeNavButtons } from '../menu/HomeNavButtons';
import './Register.css';
import {
  eyeOutline,
  eyeOffOutline,
 } from 'ionicons/icons';

export const { Storage } = Plugins;
export class Register extends React.Component {
  private confirmPassword: string = '';
  private registrationResponse = { header: '', message: '' };
  private emailValidation: string = '';
  private passwordValidation: string = '';
  private passwordMatch: string = '';
  private meterIdValidation: string = '';

  componentDidMount() {
    this.clear();
  }

  async clear() {
    await Storage.clear();
  }
  state = {
    isPasswordMatch: false,
    isEmailValid: false,
    doesEmailExist: true,
    isPasswordValid: false,
    showMessage: false,
    success: false,
    failure: false,
    isEnabled: false,
    redirect: '',
    securityQuestion: '',
    isSuccess: false,
    showPassword: false,
    showRepeatPassword: false,
    suburab:'',
    address:'',
    isMeterIdValid:false,
  };

  user: User = {
    emailAddress: '',
    firstName: '',
    lastName: '',
    dob: new Date(''),
    houseAddress: '',
    password: '',
    meterId:'',
  };

  toggleShow() {
    this.setState({ showPassword : !this.state.showPassword })
  }

  toggleRepeatShow() {
    this.setState({ showRepeatPassword : !this.state.showRepeatPassword })
  }

  isEmailValid(): boolean {
    //Regular expression for simple email format check
    const emailRegExp = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (emailRegExp.test(this.user.emailAddress)) {
      this.setState({ isEmailValid: true });
      this.emailValidation = '';
      this.validateFields();
      return true;
    } else {
      this.setState({ isEmailValid: false });
      this.setState({ isEnabled: false });
      this.emailValidation = 'Please enter a valid Email address';
      return false;
    }
  }

  isPasswordValid(): boolean {
    //Regular expression for minimum 8 characters, at least one capital letter, one small letter and one number
    this.confirmPassword = '';
    this.setState({ isPasswordMatch: false });

    const passwordRegExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
    );
    if (passwordRegExp.test(this.user.password)) {
      this.passwordValidation = '';
      this.setState({ isPasswordValid: true });
      this.validateFields();
      return true;
    } else {
      this.passwordValidation = 'Please enter a valid Password';
      this.setState({ isPasswordValid: false });
      this.setState({ isEnabled: false });
      return false;
    }
  }

  isPasswordMatch(): boolean {
    // Checks to see if password and confirm password are equal
    if (!this.user.password) {
      this.passwordMatch = 'Please enter a valid Password';
      this.setState({ isPasswordValid: false });
      this.setState({ isEnabled: false });
      return false;
    } else {
      if (this.user.password === this.confirmPassword) {
        this.passwordMatch = '';
        this.setState({ isPasswordMatch: true });
        this.validateFields();
        return true;
      } else {
        this.setState({ isPasswordMatch: false });
        this.setState({ isEnabled: false });
        this.setState({
          passwordMatch: 'Password and Repeat Password do not match',
        });
        return false;
      }
    }
  }

  doesEmailExist = async () => {
    // Checks to see if inputted password exists within the database
    if (this.isEmailValid()) {
      const emailAddress = { emailAddress: this.user.emailAddress };
      await axios
        .post(
          process.env.REACT_APP_API_REGISTRATION_EMAIL_EXIST || '',
          emailAddress
        )
        .then((res) => {
          // console.log(`[api call result]: ${JSON.stringify(res)}`);
          this.setState({ doesEmailExist: false });
          this.validateFields();
          return false;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === HttpStatus.InternalServerError
          ) {
            this.registrationResponse.header = 'Internal Server Error';
            this.registrationResponse.message =
              'Internal Server Error. Please try again';
            this.setState({ showMessage: true });
          } else if (
            error.response &&
            error.response.status === HttpStatus.Conflict
          ) {
            this.emailValidation = 'Entered Email Address Already Exists';
            this.setState({ doesEmailExist: true });
          } else {
            this.registrationResponse.header = 'Unknown Error';
            this.registrationResponse.message = 'Unknown Error Occurred';
            this.setState({ showMessage: true });
          }
          this.setState({ isEnabled: false });

          return true;
        });
    }
  };

  doesMeterIdExist = async () => {
    // Checks to see if inputted meter Id exists within the database
    
    if (this.user.meterId!=="") {
    
      const meterId = { meterId: this.user.meterId };
      await axios
        .post(
          process.env.REACT_APP_API_REGISTRATION_METERID_EXIST || '',
          meterId
        )
        .then((res) => {
          console.log(`[api call result]: ${JSON.stringify(res)}`);
          this.user.houseAddress=res.data.address;
          
          this.meterIdValidation = '';
          this.setState({ isMeterIdValid: true });
          this.validateFields();
           return true;
    } )
        .catch((error) => {
          console.log(` this is the error response: ${error.response}`)
          if (
            error.response &&
            error.response.status === HttpStatus.InternalServerError
          ) {
            this.registrationResponse.header = 'Internal Server Error';
            this.registrationResponse.message =
              'Internal Server Error. Please try again';
            this.setState({ showMessage: true });
          } else if (
            error.response &&
            error.response.status === HttpStatus.NotFound
          ) {
           // this.meterIdValidation = 'Entered MeterId does not Exists';
            // this.registrationResponse.header = 'Not Found';
            // this.registrationResponse.message = 'Invalid Meter Id';
            // this.setState({ showMessage: true });

            this.meterIdValidation = 'Please enter a valid Meter Id';
            
            
          } else {
            this.registrationResponse.header = 'Unknown Error';
            this.registrationResponse.message = 'Unknown Error Occurred';
            this.setState({ showMessage: true });
          }
          
          this.setState({ isMeterIdValid: false });
          this.setState({ isEnabled: false });
          return false;
        });
    }
  };




  validateFields(): any {
    if (
      this.user.firstName === '' ||
      this.user.lastName === '' ||
      this.user.dob === new Date('') ||
      this.user.meterId === '' ||
      this.user.houseAddress === '' ||
      !this.state.isPasswordMatch ||
      !this.state.isPasswordValid ||
      !this.state.isEmailValid ||
      !this.state.isMeterIdValid||
      this.state.doesEmailExist
    ) {
      this.setState({ isEnabled: false });
    } else {
      this.setState({ isEnabled: true });
    }
  }

  async handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (
      !this.state.isPasswordValid ||
      !this.state.isPasswordMatch ||
      !this.state.isEmailValid ||
      this.state.doesEmailExist
    ) {
      this.setState({ isEnabled: false });
      return false;
    }
    this.user.password = sha256(this.user.emailAddress + this.user.password);

    this.user.dob = new Date(
      `${this.user.dob.getFullYear()} ${this.user.dob.getMonth() + 1} ${
        this.user.dob.getDate() + 1
      }`
    );

    axios
      .put(
        process.env.REACT_APP_API_REGISTRATION_EMAIL_REGISTER || '',
        this.user
      )
      .then((response) => {
        ///this.setState({ redirect: '/login' });
        this.setState({ isSuccess: true });
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === HttpStatus.InternalServerError
        ) {
          this.registrationResponse.header = 'Internal Server Error';
          this.registrationResponse.message =
            'Internal Server Error,Please try again';
        } else {
          this.registrationResponse.header = 'Unknown Error';
          this.registrationResponse.message = 'Unknown Error Occurred';
        }

        this.setState({ showMessage: true });
      });
    this.user.password = '';
    this.confirmPassword = '';
  }
  render() {
    // Redirects the user if a valid register occurs
    const { showPassword, showRepeatPassword } = this.state
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        <IonPage id="register-page">
          <IonHeader>
            <IonToolbar color="dark">
              <IonButtons slot="start">
                <HomeNavButtons />
              </IonButtons>

              <IonTitle class="title">Register</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonCard>
              <IonCardContent class="register-card">
                <IonAlert
                  isOpen={this.state.showMessage}
                  header={this.registrationResponse.header}
                  message={this.registrationResponse.message}
                  buttons={['OK']}
                />
                <IonAlert
                  isOpen={this.state.isSuccess}
                  header={'Succesfully Registered'}
                  message={
                    'Successfully registered <br/> Please check your email to verify'
                  }
                  buttons={[
                    {
                      text: 'OK',
                      handler: () => {
                        this.setState({ redirect: '/login' });
                      },
                    },
                  ]}
                />
                <div className="container-fluid">
                  <div className="card-panel">
                    <form
                      className="col s12 m5"
                      onSubmit={(e) => this.handleSubmit(e)}
                      action="post"
                    >
                      <IonList>
                        <h6 className="required-text">
                          <b className="rouge">*</b> indicates a required field{' '}
                        </h6>
                        <IonItem className="row justify-content-center align-items-center">
                          <IonLabel position="floating" color="primary">
                            First Name <b className="rouge">*</b>
                          </IonLabel>
                          <IonInput
                            name="firstName"
                            type="text"
                            value={this.user.firstName}
                            onIonChange={(e) =>
                              (this.user.firstName = e.detail.value!)
                            }
                            onIonBlur={() => this.validateFields()}
                            spellCheck={false}
                            autocapitalize="off"
                            required
                          ></IonInput>
                        </IonItem>

                        <IonItem>
                          <IonLabel position="floating" color="primary">
                            Last Name <b className="rouge">*</b>
                          </IonLabel>
                          <IonInput
                            name="lastName"
                            type="text"
                            value={this.user.lastName}
                            onIonChange={(e) =>
                              (this.user.lastName = e.detail.value!)
                            }
                            onIonBlur={() => this.validateFields()}
                            spellCheck={false}
                            autocapitalize="off"
                            required
                          ></IonInput>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating" color="primary">
                            Date of Birth <b className="rouge">*</b>
                          </IonLabel>
                          <IonDatetime
                            name="dob"
                            onIonChange={(e) =>
                              (this.user.dob = new Date(e.detail.value!))
                            }
                            onIonBlur={() => this.validateFields()}
                          ></IonDatetime>
                        </IonItem>
                        
                        <IonItem>
                          <IonLabel position="floating" color="primary">
                            Meter Id <b className="rouge">*</b>
                          </IonLabel>
                          <IonInput
                            name="meterId"
                            type="text"
                            value={this.user.meterId}
                            onIonChange={(e) =>
                              (this.user.meterId = e.detail.value!)
                            }
                            onIonBlur={() => this.doesMeterIdExist()}
                            spellCheck={false}
                            autocapitalize="off"
                            required
                          ></IonInput>
                           <IonNote color="danger">
                            {' '}
                            {this.meterIdValidation}{' '}
                          </IonNote>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating" color="primary">
                            Email Address <b className="rouge">*</b>
                          </IonLabel>
                          <IonInput
                            name="email"
                            type="email"
                            className="validate"
                            value={this.user.emailAddress}
                            onIonChange={(e) =>
                              (this.user.emailAddress = e.detail.value!)
                            }
                            onIonBlur={() => {
                              this.doesEmailExist();
                            }}
                            spellCheck={false}
                            autocapitalize="off"
                            required
                          ></IonInput>
                          <IonNote color="danger">
                            {' '}
                            {this.emailValidation}{' '}
                          </IonNote>
                        </IonItem>
                        <IonItem>
                       
                          <IonLabel position="floating" color="primary">
                            Password <b className="rouge">*</b>
                          </IonLabel>
                         
                          <IonInput
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={this.user.password}
                            onIonChange={(e) =>
                              (this.user.password = e.detail.value!)
                            }
                            onIonBlur={() => this.isPasswordValid()}
                            required
                          ></IonInput>
                          <IonNote color="danger">
                            {this.passwordValidation}
                          </IonNote>
                          <IonIcon className='eye-toggle' size='medium' slot='end' icon={showPassword ? eyeOffOutline : eyeOutline} onClick={() => this.toggleShow()}/>
                        </IonItem>
                       
                        <IonItem>
                          {' '}
                          Password must be 8 characters or more, including at
                          least 1 capital letter, 1 small letter, and 1 digit.{' '}
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating" color="primary">
                            Repeat Password <b className="rouge">*</b>
                          </IonLabel>
                          <IonInput
                            name="repeat-password"
                            type={showRepeatPassword ? 'text' : 'password'}
                            value={this.confirmPassword}
                            onIonChange={(e) =>
                              (this.confirmPassword = e.detail.value!)
                            }
                            onIonBlur={() => {
                              this.isPasswordMatch();
                            }}
                            required
                          ></IonInput>
                          <IonNote color="danger">
                            {' '}
                            {this.passwordMatch}{' '}
                          </IonNote>
                          <IonIcon className='eye-toggle' size='medium' slot='end' icon={showRepeatPassword ? eyeOffOutline : eyeOutline} onClick={() => this.toggleRepeatShow()}/>
                        </IonItem>

                        <br />

                        <IonRow>
                          <IonCol>
                            <IonButton
                              className='reg-button'
                              type="submit"
                              disabled={!this.state.isEnabled}
                              expand="block"
                            >
                              Register
                            </IonButton>
                            <a href="/login" className="link">
                              Already Registered?
                            </a>
                          </IonCol>
                        </IonRow>
                      </IonList>
                    </form>
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonPage>
      </>
    );
  }
}
export default withIonLifeCycle(Register);
