import React, { Component } from 'react'
import '../Dashboard.css'
import '../YearlyUsage.css'
import axios from 'axios'
import {
  IonButton,
  IonSpinner,
  IonAlert
} from '@ionic/react'
import { AxisBottom } from '../../graphs/graphComponents/AxisBottom'
import { AxisLeft } from '../yearlyGraphComponents/AxisLeft'
import { Cookies } from 'react-cookie'
import { Marks } from '../../graphs/graphComponents/Marks'
import { Plugins } from '@capacitor/core'
import { Redirect } from 'react-router-dom'
import { stringToDate } from '../yearlyGraphComponents/DateChanger'
import * as d3 from 'd3'

// pulls through cookies which allows us to check the meterID and display usage from that MeterID
const cookies: Cookies = new Cookies();

// variables for width and height for D3 grpahs
const width = 330
const height = 340
const margin = { top: 30, right: 15, bottom: 60, left: 60 }

// axes variables
const innerHeight = height - margin.top - margin.bottom
const innerWidth = width - margin.left - margin.right

// x and y Values
const yValue = (d: {newDate: any}) => d.newDate
const xValue = (d: {newData: any}) => d.newData

// Formatting tick Labels for xAxis, changes the format so it now presents thousands with 'k's
const siFormat = d3.format('~s')
const xAxisTickFormat = (n:number) => siFormat(n)

// Formatting tick Lables for the yAxis
const tiFormat = d3.timeFormat('%d')
const yAxisTickFormat = (k:Date) => tiFormat(k)

export const { Storage } = Plugins;

// use only when T is an object/class instance not with T as a primitive
// Generics (javascript)
function guardArrayClean<T>(arr: (T|undefined)[]): T[] {
  return arr.filter(i => !!i) as T[];
}

export class Season2018 extends Component {
  state = {
    redirect: '',
    day: [],
    index: {},
    monthlyRead: 0,
    items: [],
    loading: false,
    reading: [],
    prevReading: [],
    data: [],
    isSummer: true,
    isAutumn: true,
    isWinter: true,
    isSpring: false,
    showMessage: true,
    deflect: '',
  };
  private index: number = 0;

  constructor(props: any) {
    super(props);
    this.getManualData();

    // retrieves meterId data from api within the date parameters 
    this.getObject()
      .then((authParam) => {
        if (!authParam) {
          this.setState({ redirect: '/login' });
        } else {
          let fromDate = new Date('2020/08/01');
          let toDate = new Date('2020/08/30');
          fromDate = new Date((toDate.getMonth() - 7).toLocaleString());
          console.log('Iam getting manual data');
          this.getSmartMeterDataByDate(
            'METER111',
            '3acc9326a2adeec397c4f83ed0bbac',
            fromDate,
            toDate
          );
        }
      })
      .catch(() => {
        this.setState({ redirect: '/login' });
      });
  }
// ----------------------------------------Clicks---------------------------------------------------
// switch to Summer
// changes hidden from true to false when Summer button is clicked
  handleClick = () => {
    this.setState({
      isSummer: false,
      isAutumn: true,
      isWinter: true,
      isSpring: true,
    })
  }
  // switch to Autumn
  handleSecClick = () => {
    this.setState({
        isSummer: true,
        isAutumn: false,
        isWinter: true,
        isSpring: true,
    })
  }
  // switch to Winter
  handleThirdClick = () => {
    this.setState({
        isSummer: true,
        isAutumn: true,
        isWinter: false,
        isSpring: true,
    })
  }
  // switch to Spring
  handleForthClick = () => {
    this.setState({
        isSummer: true,
        isAutumn: true,
        isWinter: true,
        isSpring: false,
    })
  }
  // switch to test
  handleFifthClick = () => {
    this.setState({
      isSummer: true,
        isAutumn: true,
        isWinter: true,
        isSpring: true,
    })
  }
// ----------------------------------------Clicks---------------------------------------------------
// ---------------------------------------Functions--------------------------------------------------
  async getSmartMeterDataByDate(
    meterId: String,
    token: String,
    fromDate: Date,
    toDate: Date
  ) {
    const meterDataParam = {
      meterId: meterId,
      token: token,
      fromDate: fromDate,
      toDate: toDate,
    };
    await axios
      .post(
        process.env.REACT_APP_API_SMART_METER_DATA_BY_DATE || '',
        meterDataParam
      )
      .then((res) => {
        const hour = res.data.map((e: any) => e.date.substring(0, 10));
        const index = res.data.map((e: any) => {
          return e.index;
        });
        const dailyData = {
          labels: hour,
          datasets: [
            {
              label: 'Monthly Usage',
              data: index,
              backgroundColor: 'green',
              borderColor: 'black',
              borderWidth: 1,
            },
          ],
        };
        this.setState({ index: dailyData });
      });
    this.setState({ monthlyRead: this.index });
  }
// Checks to see if inputted meter Id exists within the database
  getManualData = async () => {
    let userMeterId= cookies.get('meterId');
    if (userMeterId!=="") {
      const meterId = { meterId: userMeterId };
      await axios
        .post(
          process.env.REACT_APP_API_MANUAL_READING || '',
          meterId
        )
        .then((res) => {
          // items is summer, item1 is Autumn, item2 is Winter, item3 is Spring,
          this.setState({ 
            items: res.data, 
            loading: true, 
          })
           return true;
    } )
        .catch((error) => {
          console.log(`${error.response}`)
          console.log(error)
          console.log('data has not been received')
          return false;
        });
    }
    return this.state.items
  };
  async getObject() {
    const authParameter = await Storage.get({ key: 'authParameter' });
    if (authParameter) {
      return JSON.parse(authParameter.value!);
    } else {
      this.setState({ redirect: '/login' });
    }
  }
  async clear() {
    await Storage.clear();
  }
  // ---------------------------------------Functions-------------------------------------------------------------------
  render() {
    // destructed state to lift state into the render
    const {loading, items } = this.state
    // checks if the data is loaded if its not loaded it returns a spinner until loaded
   if (!loading) {
    return <div className='loading-spinner'>Please wait I'm Loading...<IonSpinner color='primary' /></div>
   } 
   // deflects (redirects) when needed 
   if (this.state.deflect) {
    return <Redirect to={this.state.deflect} />;
  }
  // if the data (items) has less than one entry in the array a message will pop up
  // and redirect the user
   else if (loading === true && items.length < 1) {
     return  <IonAlert
     isOpen={this.state.showMessage}
     header={"I'm sorry, Dave. I'm afraid I can't do that "}
     message={
       'There is a problem retrieving your data\n press OK to be redirected'
     }
     buttons={[
       {
         text: 'OK',
         handler: () => {
           this.setState({ deflect: '/user/dashboard' });
         },
       },
     ]}
   />

   } else {
     
// ------------------------------------------------------DATA-----------------------------------------------------------

    // Summer Data
    // creates a new row called newData which is the newReading - previousReading and multiplying that value by 1000
    // creates a new row called newDate which is then transfered to the local date and from there the month is displayed
    const mapData = guardArrayClean(items.map((accs : {previousReading: number, newReading: number, newData: Number, readingDate: any, newDate: String, order: number }) => {
      if (accs.previousReading === 0){
        accs.previousReading = accs.newReading
      }
      if (accs.readingDate.includes('2018') === true) {
        const newDate = stringToDate(accs.readingDate,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{month: 'short',})
        if (newDate === 'Jan' || newDate === 'Feb' ||  newDate === 'Dec') {
            accs.newDate = newDate
            const newData = accs.newReading - accs.previousReading;
            accs.newData = newData*1000;
            return accs;
        }
      }
      return;
    }));

    // Autumn Data
    const mapData1 =  guardArrayClean(items.map((accs : {previousReading: number, newReading: number, newData: Number, readingDate: any, newDate: String, order: number }) => {
      if (accs.previousReading === 0){
        accs.previousReading = accs.newReading
      }
      if (accs.readingDate.includes('2018') === true) {
        const newDate = stringToDate(accs.readingDate,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{month: 'short',})
        if (newDate === 'Mar' || newDate === 'Apr' ||  newDate === 'May') {
            accs.newDate = newDate
            const newData = accs.newReading - accs.previousReading;
            accs.newData = newData*1000;
            return accs;
        }
      }
      return;
    }));

    // Winter Data
    const mapData2 =  guardArrayClean(items.map((accs : {previousReading: number, newReading: number, newData: Number, readingDate: any, newDate: String , order: number }) => {
      if (accs.previousReading === 0){
        accs.previousReading = accs.newReading
      }
      if (accs.readingDate.includes('2018') === true) {
        const newDate = stringToDate(accs.readingDate,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{month: 'short',})
        if (newDate === 'Jun' || newDate === 'Jul' ||  newDate === 'Aug') {
            accs.newDate = newDate
            const newData = accs.newReading - accs.previousReading;
            accs.newData = newData*1000;
            return accs;
        }
      }
      return;
    }));

    // Spring Data
    const mapData3 =  guardArrayClean(items.map((accs : {previousReading: number, newReading: number, newData: Number, readingDate: any, newDate: String, order: number }) => {
      if (accs.previousReading === 0){
        accs.previousReading = accs.newReading
      }
      if (accs.readingDate.includes('2018') === true) {
        const newDate = stringToDate(accs.readingDate,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{month: 'short',})
        if (newDate === 'Sept' || newDate === 'Oct' ||  newDate === 'Nov') {
            accs.newDate = newDate
            const newData = accs.newReading - accs.previousReading;
            accs.newData = newData*1000;
            return accs;
        }
      }
      return;
    }));
  // sorting the data by order so in the event it gets jumbled it will still display correctly
  const mapSummer = mapData.sort( function (a, b) { return a.order - b.order})
  const mapAutumn = mapData1.sort( function (a, b) { return a.order - b.order})
  const mapWinter = mapData2.sort( function (a, b) { return a.order - b.order})
  const mapSpring = mapData3.sort( function (a, b) { return a.order - b.order})
 
  
  // --------------------------------------------------------DATA----------------------------------------------------------
    
  // --------------------------------------------------------YScale---------------------------------------------------------

  // mapping out the numbers for the vertical part of the graph

  // Summer
  const yScale = d3.scaleBand()
  .domain(mapSummer.map(yValue))
  .range([0, innerHeight])
  .paddingInner(0.1)

  // Autumn
  const yScale1 = d3.scaleBand()
  .domain(mapAutumn.map(yValue))
  .range([0, innerHeight])
  .paddingInner(0.1)

  // Winter
  const yScale2 = d3.scaleBand()
  .domain(mapWinter.map(yValue))
  .range([0, innerHeight])
  .paddingInner(0.1)

  // Spring
  const yScale3 = d3.scaleBand()
  .domain(mapSpring.map(yValue))
  .range([0, innerHeight])
  .paddingInner(0.1)
  
// --------------------------------------------------------YScale-----------------------------------------------------

// --------------------------------------------------------XScale-----------------------------------------------------

//mapping out the numbers for the horizontal part of the graph

// Summer
const xScale = d3.scaleLinear()
  .domain([0, d3.max(mapSummer, xValue)])
  .range([0, innerWidth])

// Autumn
const xScale1 = d3.scaleLinear()
  .domain([0, d3.max(mapAutumn, xValue)])
  .range([0, innerWidth])

// Winter
const xScale2 = d3.scaleLinear()
  .domain([0, d3.max(mapWinter, xValue)])
  .range([0, innerWidth])

// Spring
const xScale3 = d3.scaleLinear()
  .domain([0, d3.max(mapSpring, xValue)])
  .range([0, innerWidth])


// --------------------------------------------------------XScale-----------------------------------------------------

// ---------------------------------------------------------Years-----------------------------------------------------

// Summer
// pulls out the reading date (month) value from mapData
const yearData = mapData.map((c) : {readingDate: any} => (c.readingDate))
// converts that month value into a string at position 1
const yr1 = yearData[1].toString()
// uses the stringToDate function and the toLocaleDateString to return the year
const year = stringToDate(yr1,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{year: 'numeric',})

// Autumn
const yearData1 = mapData1.map((c) : {readingDate: any} => (c.readingDate))
const yr2 = yearData1[1].toString()
const year1 = stringToDate(yr2,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{year: 'numeric',})

// Winter
const yearData2 = mapData2.map((c) : {readingDate: any} => (c.readingDate))
const yr3 = yearData2[1].toString()
const year3 = stringToDate(yr3,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{year: 'numeric',})

// Spring
const yearData3 = mapData3.map((c) : {readingDate: any} => (c.readingDate))
const yr4 = yearData3[1].toString()
const year4 = stringToDate(yr4,"dd/MM/yyyy","/").toLocaleDateString('en-gb',{year: 'numeric',})

// ---------------------------------------------------------Years-----------------------------------------------------

// ---------------------------------------------------------Totals----------------------------------------------------

// Summer
// pulling out newData from mapData
const yearReading = mapData.map((a : {newData: any}) => (a.newData))
// using the reduce function to get the sum of all numbers in the array
const yearTotal = yearReading.reduce((a, b) => a + b, 0)
// creating a blank array as foreach doesn't explicitly return anything
const firstYearLitreArray: any = []
// pushing newData into the new array to be processed
mapData.forEach((j : {newData: any}) => { firstYearLitreArray.push(j.newData) })
// calculating the max value of data in the array by converting to a number and using the .max function
const firstYearLitres = Math.max(...firstYearLitreArray)

// Autumn
const year1Reading = mapData1.map((a : {newData: any}) => (a.newData))
const year1Total = year1Reading.reduce((a, b) => a + b, 0)
const secondYearLitreArray: any = []
mapData1.forEach((j : {newData: any}) => { secondYearLitreArray.push(j.newData) })
const secondYearLitres = Math.max(...secondYearLitreArray)

// Winter
const year2Reading = mapData2.map((a : {newData: any}) => (a.newData))
const year2Total = year2Reading.reduce((a, b) => a + b, 0)
const thirdYearLitreArray: any = []
mapData2.forEach((j : {newData: any}) => { thirdYearLitreArray.push(j.newData) })
const thirdYearLitres = Math.max(...thirdYearLitreArray)

// Spring
const year3Reading = mapData3.map((a : {newData: any}) => (a.newData))
const year3Total = year3Reading.reduce((a, b) => a + b, 0)
const forthYearLitreArray: any = []
mapData3.forEach((j : {newData: any}) => { forthYearLitreArray.push(j.newData) })
const forthYearLitres = Math.max(...forthYearLitreArray)


// ---------------------------------------------------------Totals----------------------------------------------------
    return (
        <div>
              <div className='numScale'>
              <IonButton className='seasonly-btn' onClick={() => {this.handleClick()}} disabled={!this.state.isSummer}>Summer</IonButton>
              <IonButton className='season-btn'  onClick={() => {this.handleSecClick()}} disabled={!this.state.isAutumn}>Autumn</IonButton>
              <IonButton className='season-btn' onClick={() => { this.handleThirdClick()} } disabled={!this.state.isWinter}>Winter</IonButton>
              <IonButton className='season-btn'  onClick={() => { this.handleForthClick()}} disabled={!this.state.isSpring}>Spring</IonButton>
                <div className='card' hidden={this.state.isSummer}>
                  <svg className='svg-element' width={width} height={height}>
                      <g transform={`translate(${margin.left},${margin.top})`}>
                        <AxisBottom xScale={xScale} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
                        <AxisLeft yScale={yScale} tickFormat={yAxisTickFormat}/>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 263}>Summer Usage {year} </text>
                        <Marks data={mapSummer} xScale={xScale} yScale={yScale} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
                      </g>
                    </svg>
                    <p>Summer usage <b>{Math.round(yearTotal)}</b> Litres</p>
                    <p>Maximum usage <b>{Math.round(firstYearLitres)} Litres</b></p>
                  </div>
                  <div className='card' hidden={this.state.isAutumn}>
                  <svg className='svg-element' width={width} height={height}>
                      <g transform={`translate(${margin.left},${margin.top})`}>
                        <AxisBottom xScale={xScale1} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
                        <AxisLeft yScale={yScale1} tickFormat={yAxisTickFormat}/>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 263}>Autumn Usage {year1} </text>
                        <Marks data={mapAutumn} xScale={xScale1} yScale={yScale1} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
                      </g>
                    </svg>
                    <p>Autumn usage <b>{Math.round(year1Total)}</b> Litres</p>
                    <p>Maximum usage <b>{Math.round(secondYearLitres)} Litres</b></p>
                  </div>
                  <div className='card' hidden={this.state.isWinter}>
                  <svg className='svg-element' width={width} height={height}>
                      <g transform={`translate(${margin.left},${margin.top})`}>
                        <AxisBottom xScale={xScale2} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
                        <AxisLeft yScale={yScale2} tickFormat={yAxisTickFormat}/>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 263}>Winter Usage {year3} </text>
                        <Marks data={mapWinter} xScale={xScale2} yScale={yScale2} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
                      </g>
                    </svg>
                    <p>Winter usage <b>{Math.round(year2Total)}</b> Litres</p>
                    <p>Maximum usage <b>{Math.round(thirdYearLitres)} Litres</b></p>
                  </div>
                  <div className='card' hidden={this.state.isSpring}>
                  <svg className='svg-element' width={width} height={height}>
                      <g transform={`translate(${margin.left},${margin.top})`}>
                        <AxisBottom xScale={xScale3} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
                        <AxisLeft yScale={yScale3} tickFormat={yAxisTickFormat}/>
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text> ,
                        <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 263}>Spring Usage {year4} </text>
                        <Marks data={mapSpring} xScale={xScale3} yScale={yScale3} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
                      </g>
                    </svg>
                    <p>Spring usage <b>{Math.round(year3Total)}</b> Litres</p>
                    <p>Maximum usage <b>{Math.round(forthYearLitres)} Litres</b></p>
                  </div>
              </div> 
        </div>
    );
  }
}
}

export default Season2018;
