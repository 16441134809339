/* 
    Function which takes in a string and changes it lower case, it takes this lower case string and splits
    it by the delimiter this can be a '/' or '-' depending on what is seperating the characters in the string
    from there it finds the index of the month, day and year and parse them into numbers.
    Month is minused by 1 as the month usually displays 1 above the month your after.
    The new string is then converted into a date using the new Date method and returned
*/

export function stringToDate(_date,_format,_delimiter)
{
            var formatLowerCase=_format.toLowerCase();
            var formatItems=formatLowerCase.split(_delimiter);
            var dateItems=_date.split(_delimiter);
            var monthIndex=formatItems.indexOf("mm");
            var dayIndex=formatItems.indexOf("dd");
            var yearIndex=formatItems.indexOf("yyyy");
            var month=parseInt(dateItems[monthIndex]);
            month-=1;
            var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
            return formatedDate;
}