import React from 'react'
// import MongoClient from 'mongodb'

// // Left outer Join between userInfo and userdata using
// var url = "mongodb://127.0.0.1:27017/";

// MongoClient.connect(url, function(err, db) {
//   if (err) throw err;
//   var dbo = db.db("flowwatchdb");
//   dbo.collection('userInfo').aggregate([
//     { $lookup:
//       {
//         from: 'userData',
//         localField: 'ParentAssetFunctionalLocationID',
//         foreignField: 'ParentAssetFunctionalLocationID',
//         as: 'waterMeterData'
//       }
//     }
//   ]).toArray(function(err, res) {
//     if (err) throw err;
//     console.log(JSON.stringify(res));
//     db.close();
//   });
// });

export default function Join() {
    return (
        <div>
           <p>This is placeholder</p> 
        </div>
    )
}
