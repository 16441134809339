import './DailyUsage.css';
import {
  IonButtons,
  IonContent,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonBackButton,
  IonText,
  IonHeader,
  IonCardTitle,
} from '@ionic/react';
import React, { Component } from 'react';
import { Cookies } from 'react-cookie';

const cookies: Cookies = new Cookies();

const d3 = require('d3')

 // csv data
 const csvUrl = 'https://gist.githubusercontent.com/Metalquill/f90454f5488684487e9ad8a1e7d358df/raw/0397ec507937075f2b9cd708571ecdcf271c2fb5/newData.csv'

export class Daily extends Component {
  state = {
    items: [],
    isLoaded: false,
   
  };
  componentDidMount() {
    const fetchText = async (url:any) => {
        const response = await fetch(url)
        this.setState({ isLoaded: true})
        return await response.text()
        
    }
    fetchText(csvUrl).then(text => {
     // const word = [...text]
     console.log(d3.csvParse(text))
     //const thingee = text.split(',')
     const word = d3.csvParse(text)
     console.log(`The size of the file is: ${Math.round(text.length / 1024 )} kb`)
     console.log(`The file has ${word.length} rows`)
     console.log(`The file has ${word.columns.length} columns`)
     //console.log(`this the type of csvParse upon text: ${typeof word}`)
     //console.log(`this is the ouput of text: ${text}`)

     this.setState({items: word, something: text})
     //console.log(`this is the type of the text:  ${typeof thingee} `)
     
 })
 }
 
 
  

  render() {
    const { isLoaded, items } = this.state
    if (!isLoaded) {
        return <div>Please wait I'm Loading...</div>
    }
    else{
        
    
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>Daily</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <IonCard class="daily-card">
            <IonCardTitle>Daily Water Usage</IonCardTitle>
            <IonCardContent className='new-graph'>
            <p>Graph here</p>
            </IonCardContent>
         
          </IonCard>
        </IonContent>
      </IonPage>
    );
    }
  }
}

export default Daily;
