import React, { Component } from 'react';
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonImg,
  IonButtons
} from '@ionic/react';

import {
  speedometerOutline,
  readerOutline,
  powerOutline,
  settingsOutline,
  keyOutline,
  logInOutline,
  personAddOutline,
} from 'ionicons/icons';
import { Cookies } from 'react-cookie';
import logo from '../Logo/FWLogoNoWords.png';
import './menu.css'

const cookies: Cookies = new Cookies();

export class MainMenu extends Component {
  state = {
    isLoggedIn: false,
    redirect: '',
  };

  componentDidMount() {
    if (cookies.get('authParameter') !== undefined) {
      this.setState(
        { isLoggedIn: cookies.get('authParameter').isLoggedIn } || false
      );
    } else {
      this.setState({ isLoggedIn: false });
    }

    // console.log(this.state.isLoggedIn);
  }

  render() {
    /*if (this.state.redirect) {
    return <Redirect to={this.state.redirect} />;
  }*/
    if (cookies.get('authParameter') !== undefined) {
      return (
        <IonMenu side="start" content-id="main">
          <IonHeader>
            <IonToolbar color="light">
              <IonButtons slot='start'>
                <IonImg class="point-one" src={logo} />
              </IonButtons>
              <IonTitle>Menu</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonMenuToggle auto-hide="false">
                <IonItem button routerLink="/user/account">
                  <IonIcon icon={keyOutline} />
                  {/* &nbsp; means 'And(&) No Blank Spaces' it adds a small space in front of the label */}
                  <IonLabel>&nbsp;My Account</IonLabel>
                </IonItem>

                <IonItem button routerLink="/user/dashboard">
                  <IonIcon icon={speedometerOutline} />

                  <IonLabel>&nbsp;My DashBoard</IonLabel>
                </IonItem>

                <IonItem button routerLink="/user/changepassword">
                  <IonIcon icon={keyOutline} />
                  {/* &nbsp; means 'And(&) No Blank Spaces' it adds a small space in front of the label */}
                  <IonLabel>&nbsp;Change Password</IonLabel>
                </IonItem>
                <IonItem
                  button
                  onClick={async (e) => {
                    await cookies.remove('authParameter', { path: '/' });
                    window.open('/home', '_self');
                  }}
                >
                  <IonIcon icon={powerOutline} />
                  {/* &nbsp; means 'And(&) No Blank Spaces' it adds a small space in front of the label */}
                  <IonLabel>&nbsp;LogOut</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        </IonMenu>
      );
    } else {
      return (
        <IonMenu side="start" content-id="main">
          <IonHeader>
            <IonToolbar color="light">
              <IonButtons slot='start'>
                <IonImg class="point-one" src={logo} />
              </IonButtons>
              <IonTitle>Menu</IonTitle>
             
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonMenuToggle auto-hide="false">
                <IonItem button routerLink="/settings">
                  <IonIcon icon={settingsOutline} />
                  <IonLabel>&nbsp;Settings</IonLabel>
                </IonItem>
                <IonItem button routerLink="/about">
                  <IonIcon icon={readerOutline} />
                  <IonLabel>&nbsp;About</IonLabel>
                </IonItem>
                <IonItem button routerLink="/login">
                  <IonIcon icon={logInOutline} />
                  <IonLabel>&nbsp;Login</IonLabel>
                </IonItem>
                <IonItem button routerLink="/register">
                  <IonIcon icon={personAddOutline} />
                  <IonLabel>&nbsp;Register</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        </IonMenu>
      );
    }
  }
}

export default MainMenu;
