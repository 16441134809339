import { IonMenuButton } from '@ionic/react';
import React, { useEffect } from 'react';

export const UserNavButtons = () => {
  const [mQuery, setMQuery] = React.useState<any>({
    matches: window.innerWidth > 768 ? true : false,
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    mediaQuery.addEventListener('change', setMQuery);

    return () => mediaQuery.addEventListener('change', setMQuery);
  }, []);

  // MediaQueryListEvent { isTrusted: true, media: "(min-width: 768px)", matches: true ...}

  return (
    <div>
      {mQuery && !mQuery.matches ? (
        <IonMenuButton />
      ) : (
        <>
          {/* <IonButton routerLink='/user/account'>My Account </IonButton>
          <IonButton routerLink='/user/dashboard'>My DashBoard </IonButton> */}
        </>
      )}
    </div>
  );
};
