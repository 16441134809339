import './Dashboard.css';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardHeader,
  IonText,
  IonCardTitle,
  IonLabel,
  IonAlert, 
  IonButton,
  IonPopover,
  IonIcon,
} from '@ionic/react';
import { Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { UserNavButtons } from '../menu/UserNavButtons';
import * as d3 from 'd3';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { water } from 'ionicons/icons';
import GetDailyData from '../graphs/graphComponents/lineComponents/GetDailyData'
import {
 helpCircle,
 alertCircleSharp,
} from 'ionicons/icons';

const cookies: Cookies = new Cookies();

const colour = {
  color: 'black'
}

const diffColour = {
  color: 'red'
}

const sizing = {
  fontSize: '1.5rem',
  marginBottom: '-4px'
}

const sizeDiff = {
  fontSize: '1.5rem',
  marginBottom: '-4px',
  color: '#F93B03',
}

export class Dashboard extends Component {
  state = {
    redirect: '',
    day: '',
    index: {},
    monthlyRead: 0,
    firstName: '',
    lastName: '',
    dob: '',
    homeAddress: '',
    emailAddress: '',
    isChecked: false,
    isBath: false,
    isFlush: true,
    isFishTank: true,
    displayMessage: true,
    dailyWaterUseData: [],
    isLoaded: false,
    showPopover: false,
    showPopover2: false,
    showPopover3: false,
    surprise: undefined
    

  };
  private index: number = 0;

  componentDidMount() {
    // fetch(`https://gist.githubusercontent.com/Metalquill/f90454f5488684487e9ad8a1e7d358df/raw/d85a28569c4656110844d4bad31651b51ff2b0c2/newData.csv`) 
    //     .then(res => res.json())
    //     .then(json => { this.setState({ isLoaded: true, dailyWaterUseData: json.Reading.slice(1,25) })})
    
    // getting cookies from
    const authParameter = cookies.get('authParameter');
    if (authParameter !== undefined) {
      this.getAccountDetail(authParameter.emailAddress, authParameter.token);
      let fromDate = new Date('2020/08/01');
      let toDate = new Date('2020/08/31');
      fromDate = new Date((toDate.getMonth() - 7).toLocaleString());
      this.getSmartMeterDataByDate(
        'METER111',
        '3acc9326a2adeec397c4f83ed0bbac',
        fromDate,
        toDate
      );
    } else {
      this.setState({ redirect: '/login' });
    }
  }
 
  async getSmartMeterData(meterId: String, token: String) {
    const meterDataParam = { meterId: meterId, token: token };
    await axios
      .post(process.env.REACT_APP_API_SMART_METER_DATA || '', meterDataParam)
      .then((res) => {
        // Need to refine data here
        // Destructuring data
        // index data from meter
        const day = res.data.map((e: any) => {
          return e.date;
        });
        const index = res.data.map((e: any) => {
          return e.index;
        });
        const dailyData = {
          labels: ['day1', 'day2', 'day3', 'day4', ' day5', 'day6'],
          datasets: [
            {
              label: 'New Data',
              data: index,
              backgroundColor: [
                'red',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
              ],
              borderWidth: 1,
            },
          ],
        };
        // console.log(index);
        this.setState({ index: dailyData });
        //Date data

        this.setState({ day: day });
      });
  }
  async getSmartMeterDataByDate(
    meterId: String,
    token: String,
    fromDate: Date,
    toDate: Date
  ) {
    const meterDataParam = {
      meterId: meterId,
      token: token,
      fromDate: fromDate,
      toDate: toDate,
    };
    await axios
      .post(
        process.env.REACT_APP_API_SMART_METER_DATA_BY_DATE || '',
        meterDataParam
      )
      .then((res) => {
        // Need to refine data here
        // let data =res.data.find((x:any)=> { let date=new Date(x.date); let today=new Date(); return x.date == new Date(today.getDate()-7)} )

        this.index = res.data
          .map((e: any) => parseFloat(e.index))
          .reduce((sum: number, index: number) => sum + index);
      });

    this.setState({ monthlyRead: this.index });
  }
  async getAccountDetail(emailAddress: String, token: String) {
    const userParameter = { emailAddress: emailAddress, token: token };
    await axios
      .post(process.env.REACT_APP_API_ACCOUNT_DETAIL || '', userParameter)
      .then((res) => {
       

        this.setState({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          dob: res.data.dob,
          homeAddress: res.data.houseAddress,
          emailAddress: res.data.emailAddress,
          meterId:res.data.meterId
        });

        cookies.set('meterId', res.data.meterId);
      });
  }
  handleOnClickBath = () => {
    this.setState({ isBath : false, isFlush : true, isFishTank : true })
  }
  handleOnClickFlush = () => {
    this.setState({ isBath : true, isFlush : false, isFishTank : true })
  }
  handleOnClickFishTank = () => {
    this.setState({ isBath : true, isFlush : true, isFishTank : false })
  }

  render() {
    const {isBath, isFishTank, isFlush, showPopover, showPopover2, showPopover3} = this.state
    // Mapping out the litres in daily data
   
    const litreMap = this.state.dailyWaterUseData.map((sum : {Litres: any} ) => (sum.Litres))
    const emountOfLitres = litreMap.reduce((a, b) => a + b, 0)
    const dayTotal = emountOfLitres
    const chchAvgWaterUse = 359
   
    // Values for use in the speedometer
    const waterAllocation = 220000
    const perCent = this.index / waterAllocation; // waterAllocation can be pulled from rate payer api
    const final = (perCent * 100) / 1; // percentage of water used per allocation
    const waterValue = final.toFixed(2); // 2 decimal points
    const waterNum = Number(waterValue);
    const overUse = this.index - waterAllocation
    const overUseCost = overUse / 1000
    const bath = this.index / 90 // based on the average bath water holding capacity https://www.learnz.org.nz/water172/bg-standard-f/water-use#:~:text=Bath%20%3D%20about%2090L,about%201.5L%20per%20pot
    const toiletFlush = this.index / 7 // from https://www.watercare.co.nz/Help-and-advice/Be-Waterwise/Save-water-in-your-home/Household-tips#:~:text=If%20you%20need%20to%20replace,around%2012%20litres%20per%20flush.
    const fishTank = this.index / 50 // based on a 50L fish tank
    const today = new Date().toLocaleDateString(
      'en-gb',
      {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'utc'
      }
    ) 
    const fontStyle = {
      fontSize: 'small'
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (this.index > waterAllocation) {
      return <IonAlert
      isOpen={this.state.displayMessage}
      header={`WARNING! Looks like you have gone over your Allocation`}
      message={
        `You have used ${overUse} litres more than your allocation
          this is estimated to cost you $${overUseCost}`
      }
      buttons={['OK']}/>
    }
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar mode="ios" color="dark">
            <IonButtons slot="start">
              <UserNavButtons />
            </IonButtons>
            <IonTitle class="dashboard-title">Dash Board</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonCard class="dashboard-card">
            <IonCardTitle className='water-allocation'><b>Water Allocation</b></IonCardTitle>
            <p className='usage-to-date' style={fontStyle}>{`Yearly use to date (${today})`}</p>
            <IonCardContent className="content-card">
              <IonCardHeader></IonCardHeader>
              {/* <IonSlides pager={true} options={slideOpts}>
              <IonSlide> */}
              <ReactSpeedometer
                height={210}
                // paddingVertical={17}
                // fluidWidth={true}
                width={285}
                maxValue={300000}
                value={this.index}
                customSegmentStops={[0, 20000, 150000, 220000, 300000]}
                needleColor="black"
                segmentColors={['green', 'green', 'yellow', 'red']}
                segments={4}
              />
             
          
              
               
                <IonCard className='allo'>
                  <IonCardTitle className='allo-title'>{`Hi ${this.state.firstName} `} <IonIcon className='bulb-btn' icon={alertCircleSharp} style={sizeDiff} onClick={() => {this.setState({ redirect: '/tips' })}}/></IonCardTitle>
                  
                    <IonCardSubtitle  className='under-allo-title'>these are your stats:</IonCardSubtitle>
                      <IonCardSubtitle className='curr-allo'>
                        <IonText className="curr-litres">
                          Current allocation: <b style={colour}>220,000 litres</b>
                        </IonText>
                      </IonCardSubtitle>
                      <IonCardSubtitle className='allo-used'>
                        <IonText>
                          You have used: <b style={colour}>{`${waterNum}% (${Math.round(this.index)}L)`}</b> 
                        </IonText>
                      </IonCardSubtitle>
                      <IonCardSubtitle className='allo-comparison'>
                        <IonText>
                          You have used the equivilent of 
                        </IonText>
                      </IonCardSubtitle>
                      <IonCardSubtitle hidden={isBath} className='baths'><b style={diffColour}>{`${Math.round(bath)}`}</b> <b style={colour}>bath tubs of water <IonIcon icon={helpCircle} style={sizing} onClick={() => {this.setState({showPopover: true})}}/></b>
                        <IonPopover
                        cssClass='my-custom-class'
                        event={this.state.surprise}
                        animated={true}
                        isOpen={showPopover}
                        onDidDismiss={() => this.setState({ showPopover: false, surprise: undefined })}
                        >
                        <div className='popover-content'>
                          <IonCard>
                          <IonCardTitle className='popover-title'><b>Baths</b></IonCardTitle>
                            <IonCardSubtitle className='popover-info'>Based on the average bath water holding capacity of 90L <a href='https://www.learnz.org.nz/water172/bg-standard-f/water-use#:~:text=Bath%20%3D%20about%2090L,about%201.5L%20per%20pot'>find out more here</a>
                            </IonCardSubtitle>
                            <IonCardSubtitle onClick={() => {this.setState({showPopover: false})}}>
                              <IonButton>Close</IonButton>
                            </IonCardSubtitle>
                          </IonCard>
                        </div>
                        </IonPopover>
                      </IonCardSubtitle>
                      <IonCardSubtitle hidden={isFlush} className='baths'><b style={diffColour}>{`${Math.round(toiletFlush)}`}</b> <b style={colour}>toilet flushes of water <IonIcon icon={helpCircle} style={sizing} onClick={() => {this.setState({showPopover2: true})}}/></b>
                      <IonPopover
                        cssClass='my-custom-class'
                        event={this.state.surprise}
                        isOpen={showPopover2}
                        onDidDismiss={() => this.setState({ showPopover2: false, surprise: undefined })}
                        >
                        <div className='popover-content'>
                          <IonCard>
                          <IonCardTitle className='popover-title'><b>Toilet Flushes</b></IonCardTitle>
                            <IonCardSubtitle className='popover-info'>Based on the average Toilet flush of 7L <a href='https://www.watercare.co.nz/Help-and-advice/Be-Waterwise/Save-water-in-your-home/Household-tips#:~:text=If%20you%20need%20to%20replace,around%2012%20litres%20per%20flush'>find out more here</a>
                            </IonCardSubtitle>
                            <IonCardSubtitle onClick={() => {this.setState({showPopover2: false})}}>
                              <IonButton>Close</IonButton>
                            </IonCardSubtitle>
                          </IonCard>
                        </div>
                        </IonPopover>
                      </IonCardSubtitle>
                      <IonCardSubtitle hidden={isFishTank} className='baths'><b style={diffColour}>{`${Math.round(fishTank)}`}</b> <b style={colour}>fish tanks of water <IonIcon icon={helpCircle} style={sizing} onClick={() => {this.setState({showPopover3: true})}}/></b>
                      <IonPopover
                        cssClass='my-custom-class'
                        event={this.state.surprise}
                        isOpen={showPopover3}
                        onDidDismiss={() => this.setState({ showPopover2: false, surprise: undefined })}
                        >
                        <div className='popover-content'>
                          <IonCard>
                          <IonCardTitle className='popover-title'><b>Fish Tank</b></IonCardTitle>
                            <IonCardSubtitle className='popover-info'>Based on a 50L fish tank</IonCardSubtitle>
                            <IonCardSubtitle onClick={() => {this.setState({showPopover3: false})}}>
                              <IonButton>Close</IonButton>
                            </IonCardSubtitle>
                          </IonCard>
                        </div>
                        </IonPopover>
                      </IonCardSubtitle>
                      </IonCard>
              
                <IonButton className='stat-btn' onClick={() => {this.handleOnClickBath()}} disabled={!isBath}>Baths</IonButton><IonButton  className='stat-btn' onClick={() => {this.handleOnClickFlush()}} disabled={!isFlush}>Flushes</IonButton><IonButton className='stat-btn' onClick={() => {this.handleOnClickFishTank()}} disabled={!isFishTank}>Fish Tanks</IonButton>
            
              <IonItem button routerLink={`/user/dailyusage`}>
                Daily Usage
              </IonItem>
              <IonItem button routerLink={`/user/monthlyusage`}>
                Monthly Usage
              </IonItem>
              <IonItem button routerLink={`/user/yearlyusage`}>
                Yearly Usage
              </IonItem>
             
              <br></br>
              <li><a href='/tips' className='tip-link'>Running low on water? Here's some Tips</a></li>
            </IonCardContent>
          </IonCard>
        
          {/* <IonCard>
                      <IonCardSubtitle className='curr-allo'>
                        <IonText className="curr-litres">
                          Current daily usage: <b style={colour}>{dayTotal}</b>
                        </IonText>
                      </IonCardSubtitle>
                      <IonCardSubtitle className='allo-used'>
                        <IonText>
                          Christchurch Average usage: <b style={colour}>{`${chchAvgWaterUse} Litres`}</b> 
                        </IonText>
                      </IonCardSubtitle>
                      <IonCardSubtitle className='allo-comparison'>
                        <IonText>
                          You are doing  than average
                        </IonText>
                      </IonCardSubtitle>
            </IonCard> */}

        
      
        </IonContent>
       
      </IonPage>
    );
  }
}


export default withIonLifeCycle(Dashboard);
