import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import './app.css'

import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/homePages/Home';
// import Navbar from './pages/Navbar';
import { Login } from './pages/authentication/login';
import { Register } from './pages/authentication/Register';
import { Recover } from './pages/forgotPassword/Recover';
// import DashBoard from './pages/Dashboard';
// import Settings from './pages/Setting';
//import WaterUsage from './pages/WaterUsage';
import AppTabs from './tabs/AppTabs';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { NotFound } from './pages/NotFound';
import RecoverPassword from './pages/forgotPassword/RecoverPassword';
import { MainMenu } from './pages/menu/MainMenu';
import { About } from './pages/About';
import { Settings } from './pages/Settings';
import  Graphics  from './pages/Graphics'
import Tips from './pages/tips/Tips'
import Daily from '../src/pages/dashboard/Daily'



const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonSplitPane when="sm" contentId="main">
        <MainMenu />
        <IonRouterOutlet id="main">
          <Switch>
            <Route path="/home" component={Home} exact={true} />
            <Route path="/about" component={About} exact={true} />
            <Route path="/settings" component={Settings} exact={true} />
            <Route exact path="/" render={() => <Redirect to="/home" />} />
            <Route path="/test" component={Graphics} exact={true} />
            <Route path='/daily' component={Daily} exact={true} />

            <Route path="/login" exact={true}>
              <Login />
            </Route>

            <Route path="/register" component={Register} exact={true} />
            <Route path="/tips" component={Tips} exact={true} />
            <Route path="/user" component={AppTabs} />
            <Route exact path="/" render={() => <Redirect to="/home" />} />

            <Route path="/recover" component={Recover} exact={true} />
            <Route
              path="/recoverpassword"
              component={RecoverPassword}
              exact={true}
            />

            <Route>
              <NotFound />
            </Route>
          </Switch>
        </IonRouterOutlet>
      </IonSplitPane>
    </IonReactRouter>
  </IonApp>
);

export default App;
