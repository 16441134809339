import * as d3 from 'd3'
import React from 'react'
import './fetch.css'
import { useData } from './graphComponents/useData'
import { AxisBottom } from './graphComponents/AxisBottom'
import { AxisLeft } from './graphComponents/AxisLeft'
import { Marks } from './graphComponents/Marks'
import { useDelta } from './graphComponents/altData'
import { IonButton } from '@ionic/react'
import { IonSpinner } from '@ionic/react'

// variables for width and height
const width = 375
const height = 365
const margin = { top: 30, right: 15, bottom: 60, left: 90 }

// axes variables
const innerHeight = height - margin.top - margin.bottom
const innerWidth = width - margin.left - margin.right

// x and y Values
const yValue = d => d.Time
const xValue = d => d.Litres

// Formatting tick Labels, changing it into a string to use the
const siFormat = d3.format('.2')
const xAxisTickFormat = n => siFormat(n)

export default function Something () {
  const [isDay, setIsDay] = React.useState(true)

  // switches the graph to Afternoon
  const handleClick = (e) => {
    e.preventDefault()
    setIsDay(false)
  }

  // switches the graph back to Morning
  const handleOtherClick = (e) => {
    e.preventDefault()
    setIsDay(true)
  }

  // calling the use data function for day data
  const data = useData()
  // calling the use data function for night/evening data
  const delta = useDelta()

  // variables for stats
  const litreMap = data.map(sum => (sum.Litres))
  // getting the sum of all the numbers in the Litres Array (for the morning)
  const emountOfLitres = litreMap.reduce((a, b) => a + b, 0)
  const eveningLitreMap = delta.map(sumb => (sumb.Litres))
  // getting the sum of all the numbers in the Litres Array (for the evenning)
  const eveningEmount = eveningLitreMap.reduce((a, b) => a + b, 0)

  // Creating a new instance of Litres
  const mornLitres = []
  // pushing the forEach numbers into the Litres array
  data.forEach((i) => { mornLitres.push(parseInt(i.Litres)) })

  // Creating a new instance of afternoon Litres (for evening data)
  const afternoonLitres = []
  // pushing the forEach numbers into the gallons array
  delta.forEach((j) => { afternoonLitres.push(parseInt(j.Litres)) })

  // Finding the max values for day and night
  const maxWaterUseNight = Math.max(...afternoonLitres)
  const maxWaterUseDay = Math.max(...mornLitres)

  // function returning the max value of water use after finding max values for day and night
  const maxWaterUse = () => {
    if (maxWaterUseDay > maxWaterUseNight) {
      return maxWaterUseDay
    } else {
      return maxWaterUseNight
    }
  }

  if (!data) {
    return <div className='loading-data'>Loading...<IonSpinner color='primary' /></div>
  }

  // mapping out the numbers for the vertical part of the graph
  const yScale = d3.scaleBand()
    .domain(data.map(yValue))
    .range([0, innerHeight])
    .paddingInner(0.1)

  // evening
  const yScaleNight = d3.scaleBand()
    .domain(delta.map(yValue))
    .range([0, innerHeight])
    .paddingInner(0.1)

  // mapping out the numbers for the horizontal part of the graph
  const xScale = d3.scaleLinear()
    .domain([0, d3.max(data, xValue)])
    .range([0, innerWidth])

  // evening
  const xScaleNight = d3.scaleLinear()
    .domain([0, d3.max(delta, xValue)])
    .range([0, innerWidth])

  return (
    <div className='container'>
      <IonButton className='day-btn' onClick={handleOtherClick} disabled={isDay}>Morning</IonButton><IonButton className='night-btn' onClick={handleClick} disabled={!isDay}>Afternoon</IonButton>
      <div className='card' hidden={!isDay}>
        <svg className='svg-element' width={width} height={height}>
          <g transform={`translate(${margin.left},${margin.top})`}>
            <AxisBottom xScale={xScale} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
            <AxisLeft yScale={yScale} />
            <text className='middle-label' x={-65} textAnchor='middle' y={innerHeight - 120}>Time</text>
            <text className='middle-label' x={-65} textAnchor='middle' y={innerHeight - 102}>(AM)</text>
            <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text>
            <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 282}>Morning Usage</text>
            <Marks data={data} xScale={xScale} yScale={yScale} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
          </g>
        </svg>
        <h5 className='morn'>Morning consumption: <b>{`${Math.round(emountOfLitres)}`}</b> Litres</h5>
        <h4>Todays water consumption: <b>{`${Math.round(emountOfLitres + eveningEmount)}`}</b> litres</h4>
        <h4>Peak usage: <b>{`${maxWaterUse()}`}</b> litres</h4>
      </div>
      <div className='card' hidden={isDay}>
        <svg className='svg-element' width={width} height={height}>
          <g transform={`translate(${margin.left},${margin.top})`}>
            <AxisBottom xScale={xScaleNight} innerHeight={innerHeight} tickFormat={xAxisTickFormat} />
            <AxisLeft yScale={yScaleNight} />
            <text className='middle-label' x={-65} textAnchor='middle' y={innerHeight - 120}>Time</text>
            <text className='middle-label' x={-65} textAnchor='middle' y={innerHeight - 102}>(PM)</text>
            <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight + 40}>Litres</text>
            <text className='bottom-label' x={innerWidth / 2.5} textAnchor='middle' y={innerHeight - 282}>Afternoon Usage</text>
            <Marks data={delta} xScale={xScaleNight} yScale={yScaleNight} xValue={xValue} yValue={yValue} tooltipFormat={xAxisTickFormat} />
          </g>
        </svg>
        <h5 className='after-evening'>Afternoon/evening consumption: <b>{`${Math.round(eveningEmount)}`}</b> Litres</h5>
        <h4>Todays water consumption: <b>{`${Math.round(emountOfLitres + eveningEmount)}`}</b> litres</h4>
        <h4>Peak usage: <b>{`${maxWaterUse()}`}</b> litres</h4>
      </div>
    </div>
  )
}

