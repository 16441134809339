export class HelperFunctions{


isStringsMatch(firstString:string, secondString:string): boolean {
    // Checks to see if password and confirm password are equal
      if (firstString === secondString) {
        return true;
      } else {
             return false
        }
}


isEmailValid(emailAddress:string):boolean{
    const emailRegExp = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      ); 

    if (emailRegExp.test(emailAddress)) {
          return true;
    }else{
        return false;
    }
}

isPasswordValid(password:string):boolean{
    const passwordRegExp = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
      );
      if (passwordRegExp.test(password)) {
          return true;
    }else{
        return false;
    }
}
}