import { IonMenuButton } from '@ionic/react';
import React, { useEffect } from 'react';

export const HomeNavButtons = () => {
  const [mQuery, setMQuery] = React.useState<any>({
    matches: window.innerWidth > 768 ? true : false,
  });

  useEffect(() => {
    let mediaQuery = window.matchMedia('(min-width: 768px)');
    mediaQuery.addEventListener('change', setMQuery);

    return () => mediaQuery.addEventListener('change', setMQuery);
  }, []);

  // MediaQueryListEvent { isTrusted: true, media: "(min-width: 768px)", matches: true ...}

  return (
    <div>
      {mQuery && !mQuery.matches ? (
        <IonMenuButton />
      ) : (
        <>
          {/* <IonButton routerLink={'/home'}>Home </IonButton>
          <IonButton routerLink={'/about'}>About </IonButton>
          <IonButton routerLink={'/settings'}>Settings</IonButton> */}
        </>
      )}
    </div>
  );
};
