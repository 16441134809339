import './DailyUsage.css';
import {
  IonButtons,
  IonContent,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonBackButton,
  IonText,
  IonHeader,
  IonCardTitle,
  withIonLifeCycle,
} from '@ionic/react';
import { Redirect } from 'react-router-dom';
import React, { Component } from 'react';

import axios from 'axios';
import { Bar, Pie } from 'react-chartjs-2';
import { Cookies } from 'react-cookie';
import Scale from '../graphs/Scale'
import './daily.css'


const cookies: Cookies = new Cookies();

 // csv data
 //const csvUrl = 'https://gist.githubusercontent.com/Metalquill/f90454f5488684487e9ad8a1e7d358df/raw/0397ec507937075f2b9cd708571ecdcf271c2fb5/newData.csv'
  

const dateToday = new Date();
const previousDate = new Date(dateToday);

previousDate.setDate(previousDate.getDate() - 1);

const yesterdayString : string = previousDate.toDateString();


export class DailyUsage extends Component {
  state = {
    redirect: '',
    day: '',
    index: {},
    dailyRead: 0,
    firstName: '',
    lastName: '',
    dob: '',
    homeAddress: '',
    emailAddress: '',
  };
  private index: number = 0;

  componentDidMount() {
    // getting cookies from
    const authParameter = cookies.get('authParameter');
    if (authParameter !== undefined) {
      let fromDate = new Date('2020/08/06');
      let toDate = new Date('2020/08/06');
      // fromDate = new Date((toDate.getMonth() - 7).toLocaleString());
      this.getSmartMeterDataByDate(
        'METER111',
        '3acc9326a2adeec397c4f83ed0bbac',
        fromDate,
        toDate
      );
    } else {
      this.setState({ redirect: '/login' });
    }
  }
  async getAccountDetail(emailAddress: String, token: String) {
    const userParameter = { emailAddress: emailAddress, token: token };
    await axios
      .post(process.env.REACT_APP_API_ACCOUNT_DETAIL || '', userParameter)
      .then((res) => {
       

        this.setState({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          dob: res.data.dob,
          homeAddress: res.data.houseAddress,
          emailAddress: res.data.emailAddress,
        });
      });
  }

  // sending request to take Data
  //   async getSmartMeterData(meterId: String, token: String) {
  //     const meterDataParam = { meterId: meterId, token: token };
  //     await axios
  //       .post(process.env.REACT_APP_API_SMART_METER_DATA || '', meterDataParam)
  //       .then((res) => {
  //         // Need to refine data here
  //         // Destructuring data
  //         // index data from meter
  //         const day = res.data.map((e: any) => {
  //           return e.date;
  //         });
  //         const index = res.data.map((e: any) => {
  //           return e.index;
  //         });
  //         const dailyData = {
  //           labels: ['day1', 'day2', 'day3', 'day4', ' day5', 'day6'],
  //           datasets: [
  //             {
  //               label: 'New Data',
  //               data: index,
  //               backgroundColor: [
  //                 'red',
  //                 'rgba(54, 162, 235, 0.2)',
  //                 'rgba(255, 206, 86, 0.2)',
  //                 'rgba(75, 192, 192, 0.2)',
  //                 'rgba(153, 102, 255, 0.2)',
  //               ],
  //               borderColor: [
  //                 'rgba(255, 99, 132, 1)',
  //                 'rgba(54, 162, 235, 1)',
  //                 'rgba(255, 206, 86, 1)',
  //                 'rgba(75, 192, 192, 1)',
  //                 'rgba(153, 102, 255, 1)',
  //               ],
  //               borderWidth: 1,
  //             },
  //           ],
  //         };
  //         // console.log(index);
  //         this.setState({ index: dailyData });
  //         //Date data

  //         this.setState({ day: day });
  //       });
  //   }
  async getSmartMeterDataByDate(
    meterId: String,
    token: String,
    fromDate: Date,
    toDate: Date
  ) {
    const meterDataParam = {
      meterId: meterId,
      token: token,
      fromDate: fromDate,
      toDate: toDate,
    };
    await axios
      .post(
        process.env.REACT_APP_API_SMART_METER_DATA_BY_DATE || '',
        meterDataParam
      )
      .then((res) => {
        // Need to refine data here
        // let data =res.data.find((x:any)=> { let date=new Date(x.date); let today=new Date(); return x.date == new Date(today.getDate()-7)} )

        const hour = res.data.map((e: any) => e.date.substring(11, 16).replace('23:00', ''));
        const newHour = res.data.map((e: any) => e.date)
        // console.log('<----OUTPUTS---->')
        // console.log(`This is the out put of the hour: ${hour}`)
        // console.log(`the type of the const hour: ${typeof hour}`)
        
                        

        // console.log(hour);
        
        const index = res.data.map((e: any) => {
          return this.reverseString(e.index);
          
          
        });
        //console.log(`this is the out put of the rev index: ${this.reverseString(index)}`)
        const dailyData = {
          labels: hour,
          datasets: [
            {
              label: 'Daily Usage',
              data: index,
              backgroundColor: [],
              borderColor: [],
              borderWidth: 1,
            },
          ],
        };
        // console.log(index);
        this.setState({ index: dailyData });
      });

    this.setState({ dailyRead: this.index });
  }
  reverseString(str: any): string {
    var newString = '';
    for (var i = str.length - 1; i >= 0; i--) {
      newString += str[i];
    }
    return newString;
  }
  
  
 

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>DailyUsage</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <IonCard class="daily-card">
            <IonCardTitle className='daily-water-usage'>Daily Water Usage</IonCardTitle>
            <IonText className='today'>for {yesterdayString}</IonText>
            <IonCardContent className='new-graph'>

            </IonCardContent>
             {/* <IonCardContent class="daily-graph">
              <Bar data={this.state.index} />
              <Pie data={this.state.index} />
              <br />
              <IonText>Chart showing the daily water usage</IonText>
            </IonCardContent> */} 
            

            {/* <IonCard className='morning-card'>
              <Morning />
            </IonCard>
            <IonCard className='other-card'>
              <Other />
            </IonCard> */}
            <Scale />
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(DailyUsage);
