import React, { Component } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonButtons,
  withIonLifeCycle,
  IonTextarea,
  IonText,
  IonLabel,
  IonAvatar,
  IonItem,
  IonList,
  IonSpinner, 
  IonSlides
} from '@ionic/react';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import './AccountDetail.css';
import { AccountNavButtons } from '../menu/AccountNavButtons';
import { Redirect } from 'react-router-dom';
import fallwater from '../img/fallwater.jpg';
import sedona from '../img/sedona.jpg'
import Peng from '../img/peng.jpg'
// import moment from 'moment'


const cookies: Cookies = new Cookies();

// Can't seem to change the value in the css page
const blueStyle = {
  color: 'blue',
  fontSize: '0.85rem'
}

// Can't seem to change the value in the css page
const blackStyle = {
  color: 'black',
}

const evrything = {
  width: '100vw',
  height: '30vh'
}

const otherthing = {
  width: '110px',
  height: '110px'
}

const slideOpts = {
  initialSlide: 2,
  speed: 400,
}
export class AccountDetail extends Component {
  state = {
    redirect: '',
    firstName: '',
    lastName: '',
    dob: '',
    homeAddress: '',
    emailAddress: '',
    meterId: '',
    isLoading: false
  };

   componentDidMount() {
    // getting cookies from
    const authParameter = cookies.get('authParameter');
    if (authParameter !== undefined) {
      this.getAccountDetail(authParameter.emailAddress, authParameter.token).then((res) => {
        // Need to refine data here

        // console.log(res);

        this.setState({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          dob: res.data.dob,
          homeAddress: res.data.houseAddress,
          emailAddress: res.data.emailAddress,
          meterId:res.data.meterId,
          isLoading: true
        });
       
      });
    } else {
      //this.setState({redirect:'/login'})
      window.open('/login', '_self');
    }
  }

  async getAccountDetail(emailAddress: String, token: String) {
    const userParameter = { emailAddress: emailAddress, token: token };
    return await axios
      .post(process.env.REACT_APP_API_ACCOUNT_DETAIL || '', userParameter);
     
  }

  // Function which returns a reverse string, first attempt at reversing the date
  reverseString(str: any): string {
    var newString = '';
    for (var i = str.length - 1; i >= 0; i--) {
      newString += str[i];
    }
    return newString;
  }

  render() {
    const { isLoading, redirect, firstName, lastName, dob, homeAddress, emailAddress, meterId } = this.state
    type Item = {
      src: string;
      text: string;
    };
    // const trueDate = moment(this.state.dob).utcOffset('+12').calendar()
    // {moment(trueDate).local().format('DD-MM-YYYY)}
    // takes the date and outputs it as a string in utc
    const useDate = new Date(dob).toLocaleDateString(
      'en-gb',
      {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'utc'
      }
    ) 
    
  // Removes spaces from the front of the string and the back if there are any
   const finalSpace = homeAddress.trim()
   
    // if there is a blank at position 1 in the address the function will return a star indicating that the address has one number in it
    // It will continue to check to see how many numbers in the front of the string and replace those with stars
    // if the numbers in the front of the string go past position 4 the function will return "redacted"
    const replaceTheNumbersWithStars = () => {
      if (finalSpace[1] === " "){
        return `* ` 
      }
      else if (finalSpace[2] === " "){
        return '** ' 
      }
      else if (finalSpace[3] === " "){
        return '*** ' 
      }
       else if (finalSpace[4] === " "){
        return '**** '
      }
       else {
        return <del>redacted </del>
      }
    }
    // This function returns a sliced string after the the last index where a number is found
    const finalAddress = () => {
      if (finalSpace[1] === " "){
        return finalSpace.slice(1)
      }
      else if (finalSpace[2] === " "){
        return finalSpace.slice(2)
      }
      else if (finalSpace[3] === " "){
        return finalSpace.slice(3)
      } 
      else if (finalSpace[4] === " "){
        return finalSpace.slice(4)
      } 
      else {
        return finalSpace.slice(5)
      }
    }

    if (redirect) {
      return <Redirect to={redirect} />;
    }
    // if each of these states has a length less than 1 it will display the loading symbol 
    if (!isLoading) {
      return <div className='loading-spinner'>Please wait I'm Loading...<IonSpinner color='primary' /></div>
    }
    
   
    return (
      <>
        <IonPage id="account-detail-page">
          <IonHeader>
            <IonToolbar mode="ios" color="dark">
              <IonButtons slot="start">
                <AccountNavButtons />
              </IonButtons>
              <IonTitle class="title">My Account</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonCard>
              <img className='profile' src={fallwater} alt='sedona' style={evrything} />
                <IonAvatar className='sedona'>
                  <img src={Peng} alt='display-pic' />
                </IonAvatar>    
                <IonCardContent class="account-detail-card">
                  <IonTextarea>
                    <IonList>
                    <IonItem>
                      <IonText className="inital-details">
                        <IonLabel className="blue-text" style={blueStyle}>First Name: <IonText className="details" style={blackStyle}>{firstName}</IonText></IonLabel>
                      </IonText>
                    </IonItem>
                    <IonItem>
                      <IonText className="details">
                        <IonLabel className="blue-text" style={blueStyle}>Last Name: <IonText className="details" style={blackStyle}>{lastName}</IonText></IonLabel>
                      </IonText>
                    </IonItem>
                    <IonItem>
                      <IonText className="details">
                        <IonLabel className="blue-text" style={blueStyle}>Dob: <IonText className="details" style={blackStyle}>{useDate}</IonText></IonLabel>
                      </IonText>
                    </IonItem>
                    <IonItem>
                      <IonText className="details">
                        <IonLabel className="blue-text" style={blueStyle}>Home Address: <IonText className="details" style={blackStyle}>{replaceTheNumbersWithStars() + finalAddress()}</IonText></IonLabel>
                        
                      </IonText>
                    </IonItem>
                    <IonItem>
                      <IonText className="details">
                        <IonLabel className="blue-text" style={blueStyle}>Email Address: <IonText className="details" style={blackStyle}>{emailAddress}</IonText></IonLabel>
                      </IonText>
                    </IonItem>
                    <IonItem>
                      <IonText className="details">
                        <IonLabel className="blue-text" style={blueStyle}>Meter Id: <IonText className="details" style={blackStyle}>{meterId}</IonText></IonLabel>
                      </IonText>
                    </IonItem>
                    </IonList>
                  </IonTextarea>
                  <br />
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonPage>
      </>
    );
  }
}

export default withIonLifeCycle(AccountDetail)